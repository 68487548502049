import {
  Badge,
  // Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputBase,
  MenuItem,
  Rating,
  Select,
} from "@mui/material";
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import classes from "../../Assets/Styles/VoiceQAScreen.module.css";
import {
  formatInputs,
  formatTime,
  getLanguageName,
  timeStringToSeconds,
} from "../../utils/generalFunctions";

import Loader from "../../Components/Loader";
import axios from "axios";
import { Store } from "../../Store";
import {
  CREATE_NEW_AUDIO,
  UPDATE_ONE_SPEECH,
  GET_CHARACTERS_FOR_PROJECT,
  // UPLOAD_TRANSLATOR_VOICE,
  MERGING_AUDIO_API,
  GET_SPEECH_FOR_PROJECT_BY_PAGE,
  CREATE_MULTIPLE_AUDIO,
  GET_SPEECH_POSITION_AROUND_TIME,
} from "../../MProjectConst";
import { ToastContainer, toast } from "react-toastify";
import { useMediaQuery } from "@mui/material";
import ReactAudioPlayer from "react-audio-player";
import VideoSliderHorizontal from "../../Components/AudioTools/VideoSliderHorizontal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useParams } from "react-router-dom";

import TranslateIcon from "@mui/icons-material/Translate";

import { primaryColor, secondaryColor } from "../../utils/constants";
import TaskButton from "../../Components/Buttons/TaskButton";

import ClipLoader from "react-spinners/ClipLoader";
import GlobalAudioPlayer from "../../Components/AudioTools/AudioPlayerComponents/GlobalAudioPlayer";
import styled from "styled-components";
import InputMask from "react-input-mask";
import debounce from 'lodash.debounce';

import useExperimentalFeature from '../../utils/featureAccess';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    // marginTop: theme?.spacing(3),
  },
  "& .MuiInputBase-input": {
    width: "0px",
    // height: "20px",
    borderRadius: 4,
    position: "relative",
    backgroundColor: "none",
    border: "none",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    // transition: theme?.transitions?.create(["border-color", "box-shadow"]),
    color: `${secondaryColor}`,
    fontFamily: "Livvic",
    // "&:focus": {
    //   borderRadius: 4,
    //   borderColor: "#80bdff",
    //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    // },
  },
}));

const BootstrapInputForAvalialbe = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    color: `${primaryColor}`,
    fontFamily: "Livvic",
    // fontWeight: "600",

    width: "100%",
    position: "relative",
    backgroundColor: "none",
    border: "none",
    fontSize: 18,
  },
}));

const VoiceQAScreen = () => {
  const params = useParams();
  const { language: languageCode } = params;
  const screenIsOver1200px = useMediaQuery("(min-width: 1200px)");
  const translatedAudioRefs = useRef([]);
  const originalAudioRefs = useRef([]);

  const { state } = useContext(Store);
  const { projectFile, userInfo } = state;
  const [characters, setCharacters] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [seekTime, setSeekTime] = useState(0);
  const [seekTimeIndex, setSeekTimeIndex] = useState(0);
  const [scrollableItem, setScrollableItem] = useState(0);
  const [scrollableItemLastUpdated, setScrollableItemLastUpdated] = useState(0);
  const [audioLoader, setAudioLoader] = useState(false);
  const [loader, setLoader] = useState(false);

  const [versionName, setVersionName] = useState("");

  const [currentContinuousSync, setCurrentContinuousSync] = useState();
  const [continuousSync, setContinuousSync] = useState(false);

  const [playPausePlayer, setPlayPausePlayer] = useState("play");
  const [translationSync, setTranslationSync] = useState(false);

  const numberOfDialogues = projectFile.numberOfDialogues;

  const timeoutIdRef = useRef(null);

  const [globalEmotions, setGlobalEmotions] = useState({});
  const experimentalTraining = useExperimentalFeature(
    projectFile.id,
    userInfo.id,
    projectFile.name
  );
  
  // pagination items
  const [pageButtonActivity, setPageButtonActivity] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [goToPageNumber, setGoToPageNumber] = useState("");

  const [inputStartTime, setInputStartTime] = useState({});
  const [inputEndTime, setInputEndTime] = useState({});

  const itemsPerPage = 10;
  const totalPages = Math.ceil(numberOfDialogues / itemsPerPage);

  const containerRef = useRef(null);

  // Fetch emotion mappings when experimental training is enabled
  useEffect(() => {
    const fetchEmotions = async () => {
      if (!experimentalTraining) return;
      
      try {
        const db = getFirestore();
        const emotionsRef = doc(db, 'EmotionMappings', 'global-dec-2024');
        const emotionsSnap = await getDoc(emotionsRef);
        
        if (emotionsSnap.exists()) {
          setGlobalEmotions(emotionsSnap.data());
        } else {
          console.log("No emotion mappings found");
        }
      } catch (err) {
        console.error("Error fetching emotion mappings:", err);
      }
    };
  
    fetchEmotions();
  }, [experimentalTraining]);


  useEffect(() => {
    if (false) {
      if (screenIsOver1200px) {
        scrollToItem(scrollableItem - 1);
      }
    }
  }, [scrollableItem]);

  useEffect(() => {
    checkTimeInRange(currentTime);
  }, [currentTime]);

  const scrollToItem = (index) => {
    const manpIndex = index;

    if (containerRef.current) {
      const item = containerRef.current.children[manpIndex];
      if (item) {
        item.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };



  const displayAudioTime = useCallback((data) => {
    setCurrentTime(data);
  }, []);

  // function findNewestUrlCreated(objects) {
  //   if (objects === undefined) {
  //     return "";
  //   }

  //   if (objects.length === 0) {
  //     return null;
  //   }

  //   // Sort the array of objects based on creation time in descending order
  //   const sortedObjects = objects.sort((a, b) => {
  //     const dateA = new Date(a.creationTime);
  //     const dateB = new Date(b.creationTime);
  //     return dateB - dateA;
  //   });

  //   // Return the object with the newest creation time (first element after sorting)
  //   return sortedObjects[0].url;
  // }

  function findDisplayName(speaker) {
    const result = characters.find((char) => char.speaker === speaker);

    if (result?.displayName) {
      return result.displayName;
    }
    return speaker;
  }

  function checkTimeInRange(currentTime) {
    if (audioData) {
      const itemInTimeRange = audioData.find((item) => {
        return currentTime >= item.start && currentTime < item.end;
      });

      if (itemInTimeRange) {
        if (itemInTimeRange.id !== scrollableItemLastUpdated) {
          setScrollableItemLastUpdated(itemInTimeRange.id);
          setScrollableItem(itemInTimeRange.id);
          setCurrentContinuousSync(itemInTimeRange);
        }
      }
    }
  }

  // const findDefaultEmotion = (speaker, originalAudioEmotion) => {
  //   const character = projectFile?.characters?.find(
  //     (char) => char.speaker === speaker
  //   );
  //   const characterVoice = character?.voices?.find(
  //     (voice) => voice.emotion === originalAudioEmotion
  //   );
  //   if (!characterVoice) {
  //     return "";
  //   }
  //   return characterVoice.voice_id;
  // };

  
  const findDefaultEmotion = (speaker, originalAudioEmotion) => {
    const character = projectFile?.characters?.find(
      (char) => char.speaker === speaker
    );
    // console.log("findDefaultEmotion")
    // Fall back to global emotions mapping
    if (experimentalTraining) {
      console.log(`used experimental ${globalEmotions[originalAudioEmotion]}`)
      return globalEmotions[originalAudioEmotion] || globalEmotions['neutral'] || "dwo0oupDDyJeTBpyE6n3";
    }
    console.log(`didn't use experimental`)
    const characterVoice = character?.voices?.find(
      (voice) => voice.emotion === originalAudioEmotion
    );
    if (!characterVoice) {
      return "";
    }
    return characterVoice.voice_id;

  };

  // const updateEmotionData = (dataId, emotionId) => {
  //   const updatedData = [...audioData];
  //   updatedData[data.id] = {
  //     ...updatedData[data.id],
  //     tempTransEmotion: e.target.value,
  //   };
  //   setAudioData(updatedData);
  // }

  function checkTranslationLength(originalLength, translatedLength) {
    if (!translatedLength) {
      return "Result: ";
    }

    // Calculate the allowed range (18% of the original length)
    const allowedDifference = originalLength * 0.18;

    // Calculate the lower and upper bounds for the translated length
    const lowerBound = originalLength - allowedDifference;
    const upperBound = originalLength + allowedDifference;

    // Check if the translated length is within the allowed range
    if (translatedLength >= lowerBound && translatedLength <= upperBound) {
      return "Result: duration is perfect";
    } else if (translatedLength < lowerBound) {
      return <font style={{ color: "red" }}>Result: make it longer.</font>;
    } else {
      return <font style={{ color: "red" }}>Result: make it shorter.</font>;
    }
  }

  const checkLanguage = (translations) => {
    const updatedTranslations = [...translations];
    const existingLanguageCodes = translations.map(
      (transLang) => transLang.languageCode
    );

    projectFile.finalLang.forEach((langCode) => {
      if (!existingLanguageCodes.includes(langCode)) {
        updatedTranslations.push({
          languageCode: langCode,
          translatedText: "",
          translatedAudioList: [],
          translatedAudio: "",
          translatedAudioRating: "",
          translatedAudioNote: null,
          translatedAudioPath: null,
          translatedRecording: "",
        });
      }
    });

    // Return the updated translations array
    return updatedTranslations;
  };

  const findTransObjectsbyLangCode = (array) => {
    const index = array.find((obj) => obj.languageCode === languageCode);
    return index;
  };

  const findEmotionByVoiceId = (voiceId) => {
    
    if (experimentalTraining){
      for (let currEmotion in globalEmotions){
        if (globalEmotions[currEmotion] === voiceId){
          return currEmotion;
        }
      }
    }
    // Or use old feature
    for (const character of characters) {
      for (const voice of character.voices) {
        if (voice.voice_id === voiceId) {
          return voice.emotion;
        }
      }
    }

    return null; // return null if no matching voice_id is found
  };

  const handleVoiceOverLapping = (index, originalDuration) => {
    const updatedData = [...audioData];
    const endTime = updatedData[index].start + originalDuration;

    const startTime = updatedData[index + 1]?.start;

    return endTime >= startTime ? <>{" Overlapping "}</> : "";
  };

  //+ Speaker api for dialogue
  const handleSpeakerChange = async (e, index) => {
    setAudioLoader(true);
    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      speaker: e.target.value,
    };

    setAudioData(updatedData);

    try {
      const response = await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
      if (response.data) {
        setAudioData(updatedData);
        setAudioLoader(false);
      }
    } catch (err) {
      console.log(err);
      setAudioLoader(false);
    }
  };

  //+ Start time api for dialogue
  // function handleStartTimeChange(e, index) {
  //   const newTime = timeStringToSeconds(e.target.value);
  //   if (!newTime) {
  //     return;
  //   }

  //   const updatedData = [...audioData];
  //   updatedData[index] = {
  //     ...updatedData[index],
  //     start: newTime,
  //   };

  //   setAudioData(updatedData);
  //   clearTimeout(timeoutIdRef.current);

  //   timeoutIdRef.current = setTimeout(async () => {
  //     await axios.post(UPDATE_ONE_SPEECH, {
  //       projectId: projectFile.id,
  //       dialogue: updatedData[index],
  //     });
  //   }, 2300);
  // }


  //+ End time api for dialogue
  // function handleEndTimeChange(e, index) {
  //   const newTime = timeStringToSeconds(e.target.value);
  //   if (!newTime) {
  //     return;
  //   }

  //   const updatedData = [...audioData];
  //   updatedData[index] = {
  //     ...updatedData[index],
  //     end: newTime,
  //   };

  //   setAudioData(updatedData);
  //   clearTimeout(timeoutIdRef.current);

  //   timeoutIdRef.current = setTimeout(async () => {
  //     await axios.post(UPDATE_ONE_SPEECH, {
  //       projectId: projectFile.id,
  //       dialogue: updatedData[index],
  //     });
  //   }, 2300);
  // }


  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    const milliseconds = Math.round((timeInSeconds % 1) * 100);

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}.${String(milliseconds).padStart(2, "0")}`;
  };

  // Sync `audioData` with `inputStartTime` and `inputEndTime`
  useEffect(() => {
    const initialStartTimes = {};
    const initialEndTimes = {};

    audioData.forEach((data, index) => {
      initialStartTimes[index] = formatTime(data.start || 0);
      initialEndTimes[index] = formatTime(data.end || 0);
    });

    setInputStartTime(initialStartTimes);
    setInputEndTime(initialEndTimes);
  }, [audioData]);

  const saveStartTime = useCallback(
    debounce(async (data) => {
      try {
        await axios.post(UPDATE_ONE_SPEECH, {
          projectId: projectFile.id,
          dialogue: data,
        });
      } catch (error) {
        console.error("Error saving start time:", error);
      }
    }, 500),
    [projectFile.id]
  );

  const saveEndTime = useCallback(
    debounce(async (data) => {
      try {
        await axios.post(UPDATE_ONE_SPEECH, {
          projectId: projectFile.id,
          dialogue: data,
        });
      } catch (error) {
        console.error("Error saving end time:", error);
      }
    }, 500),
    [projectFile.id]
  );

  // Input handling functions
  const handleStartTimeChange = (e, index) => {
    const value = e.target.value;
    setInputStartTime((prev) => ({ ...prev, [index]: value }));

    const [hours, minutes, seconds, milliseconds] = value.split(/[:.]/).map(Number);
    const newTime =
      (hours || 0) * 3600 +
      (minutes || 0) * 60 +
      (seconds || 0) +
      (milliseconds || 0) / 100;

    const updatedData = [...audioData];
    updatedData[index] = { ...updatedData[index], start: newTime };
    setAudioData(updatedData);
    saveStartTime(updatedData[index]); // Debounced save function
  };

  const handleEndTimeChange = (e, index) => {
    const value = e.target.value;
    setInputEndTime((prev) => ({ ...prev, [index]: value }));

    const [hours, minutes, seconds, milliseconds] = value.split(/[:.]/).map(Number);
    const newTime =
      (hours || 0) * 3600 +
      (minutes || 0) * 60 +
      (seconds || 0) +
      (milliseconds || 0) / 100;

    const updatedData = [...audioData];
    updatedData[index] = { ...updatedData[index], end: newTime };
    setAudioData(updatedData);
    saveEndTime(updatedData[index]); // Debounced save function
  };

  //api for handling notes change
  function handleNoteChange(e, index) {
    const updatedData = [...audioData];

    updatedData[index].translations = updatedData[index].translations.map(
      (prev) => {
        if (prev.languageCode === languageCode) {
          return {
            ...prev,
            translatedAudioNote: e.target.value,
          };
        } else {
          return prev;
        }
      }
    );
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 1650);

    setAudioData(updatedData);
  }

  //api for handling original text
  function handleTextChange(e, index) {
    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      text: e.target.value,
    };

    setAudioData(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 1650);
  }

  //api for handling translation text
  function handleTranslationChange(e, index) {
    const updatedData = [...audioData];
    // updatedData[index].translations = {
    //   ...updatedData[index].translations,
    //   translatedText: e.target.value,
    // };

    updatedData[index].translations = updatedData[index].translations.map(
      (prev) => {
        if (prev.languageCode === languageCode) {
          return {
            ...prev,
            translatedText: e.target.value,
          };
        } else {
          return prev;
        }
      }
    );

    setAudioData(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 1650);
  }

  //api for dialogue rating
  const handleDialogueRating = async (rating, index) => {
    setAudioLoader(true);
    const updatedData = [...audioData];

    updatedData[index].translations = updatedData[index].translations.map(
      (prev) => {
        if (prev.languageCode === languageCode) {
          return {
            ...prev,
            translatedAudioRating: rating,
          };
        } else {
          return prev;
        }
      }
    );

    try {
      const response = await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
      if (response.data) {
        setAudioData(updatedData);
        setAudioLoader(false);
      }
    } catch (err) {
      console.log(err);
      setAudioLoader(false);
    }
  };

  //api new translated audio
  const handleNewAudioUrl = async (index) => {
    if (audioData[index].tempTransEmotion === "") {
      toast.error("Error !! Choose an emotion");
      return;
    }

    const now = new Date();
    const dialogueCopy = {
      ...audioData[index],
      translatedText: findTransObjectsbyLangCode(audioData[index].translations)
        .translatedText,
      voiceId: audioData[index].tempTransEmotion,
      languageCode: languageCode,
      emotion: findEmotionByVoiceId(audioData[index].tempTransEmotion),
      loader: false,
    };

    try {
      const response = await axios.post(CREATE_NEW_AUDIO, {
        dialogue: dialogueCopy,
        projectName: projectFile.name,
        creationDate: projectFile.creationDate,
        projectId: projectFile.id,
      });

      const newAudioObject = {
        url: response.data.url,
        creationTime: now,
        emotion: audioData[index].tempTransEmotion,
        translatedAudioDuration: response.data.length,
      };

      dialogueCopy.translations = dialogueCopy.translations.map((prev) => {
        if (prev.languageCode === languageCode) {
          return {
            ...prev,
            translatedAudio: newAudioObject.url,
            translatedAudioDuration: newAudioObject.translatedAudioDuration,
            translatedAudioList: prev.translatedAudioList
              ? [...prev.translatedAudioList, newAudioObject]
              : [newAudioObject],
          };
        } else {
          return prev;
        }
      });

      // Send updated dialogue data to the server
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: dialogueCopy,
      });

      const newSingleAudio = {
        url: response.data.url,
        length: response.data.length,
        emotion: response.data.emotion,
      };

      // Functional state update to avoid conflicts
      setAudioData((prevData) => {
        const updatedData = [...prevData];
        const updatedTempAudioList = [
          ...updatedData[index].tempAudioList,
          newSingleAudio,
        ];

        updatedData[index] = {
          ...dialogueCopy,
          tempAudioList: updatedTempAudioList,
        };

        return updatedData;
      });

      toast.success("New Audio has been added", {
        hideProgressBar: false,
        autoClose: 1000,
      });
    } catch (err) {
      console.error(err);
      toast.error("Error in creating new audio");

      setAudioData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index].loader = false;
        return updatedData;
      });
    }
  };

  //api to create mulitple translated audio
  const handleMultipleAudio = async (index) => {
    // Ensure tempTransEmotion is selected
    if (audioData[index].tempTransEmotion === "") {
      toast.error("Error !! Choose an emotion");
      return;
    }

    // Create a copy of the data for the current dialogue
    const dialogueCopy = {
      ...audioData[index],
      translatedText: findTransObjectsbyLangCode(audioData[index].translations)
        .translatedText,
      voiceId: audioData[index].tempTransEmotion,
      languageCode: languageCode,
      emotion: findEmotionByVoiceId(audioData[index].tempTransEmotion),
      loader: false,
    };

    try {
      const response = await axios.post(CREATE_MULTIPLE_AUDIO, {
        dialogue: dialogueCopy,
        projectName: projectFile.name,
        creationDate: projectFile.creationDate,
        projectId: projectFile.id,
      });

      // Use functional state update to ensure the most recent state is used
      setAudioData((prevData) => {
        const updatedData = [...prevData];

        // Prevent duplication by checking if response.data is already in tempAudioList
        const existingAudioList = updatedData[index].tempAudioList;

        const newAudioList = response.data.filter(
          (newAudio) =>
            !existingAudioList.some(
              (existingAudio) => existingAudio.url === newAudio.url
            )
        );

        updatedData[index].tempAudioList = [
          ...existingAudioList,
          ...newAudioList,
        ];
        updatedData[index].loader = false;

        return updatedData;
      });

      toast.success("New audio list has been added", {
        hideProgressBar: false,
        autoClose: 1000,
      });
    } catch (err) {
      console.error(err);
      toast.error("Error in creating new audio");
      setAudioData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index].loader = false;
        return updatedData;
      });
    }
  };

  //api dropdown audio option
  const selectAudioOption = async (e, index, playerIndex) => {
    const now = new Date();
    // setAudioLoader(true);
    const audioUrl = e.target.value;

    if (audioUrl === "") {
      toast.error("Select an audio!");
      return;
    }

    const audioDuration = audioData[index].tempAudioList.find(
      (audio) => audio.url === audioUrl
    ).length;

    // Create a copy of the data for the current dialogue
    const dialogueCopy = audioData[index];

    const newAudioObject = {
      url: audioUrl,
      translatedAudioDuration: audioDuration,
      creationTime: now,
      emotion: audioData[index].tempTransEmotion,
    };

    // Update the translations in the current dialogue
    dialogueCopy.translations = dialogueCopy.translations.map((prev) => {
      if (prev.languageCode === languageCode) {
        return {
          ...prev,
          translatedAudio: newAudioObject.url,
          translatedAudioDuration: newAudioObject.translatedAudioDuration,
        };
      } else {
        return prev;
      }
    });

    // Send updated dialogue data to the server
    try {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: dialogueCopy,
      });

      // Use functional state update to safely modify the state
      setAudioData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = dialogueCopy;
        return updatedData;
      });

      setTimeout(() => {
        if (translatedAudioRefs.current[playerIndex]) {
          translatedAudioRefs.current[playerIndex].seek(0);
          translatedAudioRefs.current[playerIndex].play();
        }
      }, 1000);

      toast.info(" Audio has been updated", {
        hideProgressBar: false,
        autoClose: 1000,
      });
      // setAudioLoader(false);
    } catch (error) {
      console.error(error);
      setAudioData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index].loader = false;
        return updatedData;
      });
    }
  };

  const handleTranslatedAudioVolume = async (e, index) => {
    const dialogueCopy = audioData[index];

    let volume;
    const data = e.target.value;

    if (isNaN(data)) {
      volume = null;
    } else {
      volume = Number(data);
    }

    dialogueCopy.translations = dialogueCopy.translations.map((prev) => {
      if (prev.languageCode === languageCode) {
        return {
          ...prev,
          translatedAudioVolume: volume,
        };
      } else {
        return prev;
      }
    });

    try {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: dialogueCopy,
      });

      // Use functional state update to safely modify the state
      setAudioData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = dialogueCopy;
        return updatedData;
      });

      toast.info(" Audio has been updated", {
        hideProgressBar: false,
        autoClose: 1000,
      });
      // setAudioLoader(false);
    } catch (error) {
      console.error(error);
      setAudioData((prevData) => {
        const updatedData = [...prevData];
        return updatedData;
      });
    }
  };

  //+ face api for dialogue
  const handleKeepOriginalText = async (index) => {
    setAudioLoader(true);
    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      keepOriginalText: !updatedData[index].keepOriginalText,
    };

    setAudioData(updatedData);

    try {
      const response = await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
      if (response.data) {
        setAudioData(updatedData);
        setAudioLoader(false);
      }
    } catch (err) {
      console.log(err);
      setAudioLoader(false);
    }
  };

  //api for handling translatedAudioChange from dropdown menu
  function handleTranslatedAudioChange(e, index) {
    const updatedData = [...audioData];
    let attemptsList;

    updatedData[index].translations.forEach((prev) => {
      if (prev.languageCode === languageCode) {
        attemptsList = prev.audioAttempts ? prev.audioAttempts : [];
        return prev;
      } else {
        return prev;
      }
    });

    let attempt = attemptsList?.find(
      (obj) => obj.attemptNumber === Number(e.target.value)
    );

    updatedData[index].translations = updatedData[index].translations.map(
      (prev) => {
        if (prev.languageCode === languageCode) {
          return {
            ...prev,
            translatedAudio: attempt.translatedAudio,
            translatedAudioDuration: attempt.audio_length,
          };
        } else {
          return prev;
        }
      }
    );
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 1650);

    setAudioData(updatedData);
  }

  //api final submission
  const finalSubmission = async () => {
    if (!versionName) {
      toast.error("Enter a version name");

      return;
    }

    toast.success("New version is being processed");
    try {
      // const response =

      // old merging with old queue
      await axios.post(MERGING_AUDIO_API, {
        projectId: projectFile.id,
        langCode: languageCode,
        version: versionName,
        emails: [userInfo.email, "bg@y76.io", "ak@y76.io", "clifford@dubfrog.media", "mohammad@y76.io"], // "wissam_just@outlook.com"
        // emails: [userInfo.email, "bg@y76.io",],
      });

      // // with new queue
      // await axios.post("http://localhost:4001/api/services/mergingaudionew", {
      //   projectId: projectFile.id,
      //   projectName: projectFile.name,
      //   langCode: languageCode,
      //   version: versionName,
      //   submitterName: userInfo.name,
      //   // emails: [userInfo.email, "bg@y76.io", "ak@y76.io", "clifford@dubfrog.media", "mohammad@y76.io"], // "wissam_just@outlook.com"
      //   emails: [userInfo.email, "bg@y76.io",],
      // });



      // if (response.data) {
      //   setAudioLoader(false);
      // }
      setVersionName("");
    } catch (err) {
      console.log(err);
    }
  };

  const nextPrevPageAPI = async (action) => {
    setPageButtonActivity(true);
    if (pageNumber === 1 && action === "previous") {
      return;
    }
    try {
      translatedAudioRefs.current.forEach((audioEl) => {
        if (audioEl) {
          audioEl.pause(); // Pause the audio
          audioEl.currentTime = 0; // Reset playback position
        }
      });
      const response = await axios.post(GET_SPEECH_FOR_PROJECT_BY_PAGE, {
        userId: userInfo.id,
        projectId: projectFile.id,
        action: action,
        pageName: "voiceqa",
      });

      if (response.data) {
        const dialogues = await response.data.projectsArray;

        dialogues.forEach((dia, index) => {
          dia.id = index;
          dia.tempTransEmotion = findDefaultEmotion(
            dia.speaker,
            dia.originalAudioEmotion
          );
          dia.translatedAudioDurationTemp = null;
          dia.record = false;
          dia.translations = checkLanguage(dia.translations);
          dia.filterShow = true;
          dia.loader = false;
          dia.tempAudioList = findTransObjectsbyLangCode(dia.translations)
            ?.translatedAudio
            ? [
              {
                url: findTransObjectsbyLangCode(dia.translations)
                  ?.translatedAudio,
                length: findTransObjectsbyLangCode(dia.translations)
                  ?.translatedAudioDuration,
                emotion: "original",
              },
            ]
            : [];
        });

        setAudioData(dialogues);
        if (action === "next") {
          setPageNumber(Number(pageNumber) + 1);
        } else if (action === "previous") {
          setPageNumber(Number(pageNumber) - 1);
        }
        setPageButtonActivity(false);
      }
    } catch (err) {
      console.log(err);
      toast.error("Error in getting the next page");
    }
  };

  const goToPageAPI = async (number) => {
    setLoader(true);

    try {
      translatedAudioRefs.current.forEach((audioEl) => {
        if (audioEl) {
          audioEl.pause(); // Pause the audio
          console.log(audioEl.currentTime);
          audioEl.currentTime = 0; // Reset playback position
        }
      });
      const response = await axios.post(GET_SPEECH_FOR_PROJECT_BY_PAGE, {
        userId: userInfo.id,
        projectId: projectFile.id,
        action: "goto",
        pageNumber: number,
        pageName: "voiceqa",
      });

      if (response.data) {
        const dialogues = await response.data.projectsArray;

        dialogues.forEach((dia, index) => {
          dia.id = index;
          dia.tempTransEmotion = findDefaultEmotion(
            dia.speaker,
            dia.originalAudioEmotion
          );
          dia.translatedAudioDurationTemp = null;
          dia.record = false;
          dia.translations = checkLanguage(dia.translations);
          dia.filterShow = true;
          dia.loader = false;
          dia.tempAudioList = findTransObjectsbyLangCode(dia.translations)
            ?.translatedAudio
            ? [
              {
                url: findTransObjectsbyLangCode(dia.translations)
                  ?.translatedAudio,
                length: findTransObjectsbyLangCode(dia.translations)
                  ?.translatedAudioDuration,
                emotion: "original",
              },
            ]
            : [];
        });

        setAudioData(dialogues);
        setGoToPageNumber("");
        setPageNumber(number);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
      toast.error("Error in getting the next page");
    }
  };

  const goToPageAccordingTimeAPI = async () => {
    setLoader(true);

    try {
      translatedAudioRefs.current.forEach((audioEl) => {
        if (audioEl) {
          audioEl.pause(); // Pause the audio
          console.log(audioEl.currentTime);
          audioEl.currentTime = 0; // Reset playback position
        }
      });

      const positionResponse = await axios.post(
        GET_SPEECH_POSITION_AROUND_TIME,
        {
          projectId: projectFile.id,
          time: currentTime,
        }
      );

      const number = Math.ceil(positionResponse.data.firstPosition / 10);

      const response = await axios.post(GET_SPEECH_FOR_PROJECT_BY_PAGE, {
        userId: userInfo.id,
        projectId: projectFile.id,
        action: "goto",
        pageNumber: number,
        pageName: "voiceqa",
      });

      if (response.data) {
        const dialogues = await response.data.projectsArray;

        dialogues.forEach((dia, index) => {
          dia.id = index;
          dia.tempTransEmotion = findDefaultEmotion(
            dia.speaker,
            dia.originalAudioEmotion
          );
          dia.translatedAudioDurationTemp = null;
          dia.record = false;
          dia.translations = checkLanguage(dia.translations);
          dia.filterShow = true;
          dia.loader = false;
          dia.tempAudioList = findTransObjectsbyLangCode(dia.translations)
            ?.translatedAudio
            ? [
              {
                url: findTransObjectsbyLangCode(dia.translations)
                  ?.translatedAudio,
                length: findTransObjectsbyLangCode(dia.translations)
                  ?.translatedAudioDuration,
                emotion: "original",
              },
            ]
            : [];
        });

        setAudioData(dialogues);
        setGoToPageNumber("");
        setPageNumber(number);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
      toast.error("Error in getting the next page");
    }
  };

  //apis - get characters
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(GET_CHARACTERS_FOR_PROJECT, {
          projectId: projectFile.id,
        });

        setCharacters(response.data.characters);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [projectFile.id]);

  //apis - dialogue
  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const response = await axios.post(GET_SPEECH_FOR_PROJECT_BY_PAGE, {
          userId: userInfo.id,
          projectId: projectFile.id,
          action: "first",
          Current: null,
          pageName: "voiceqa",
        });

        const dialogues = await response.data.projectsArray;

        dialogues.forEach((dia, index) => {
          dia.id = index;
          dia.tempTransEmotion = findDefaultEmotion(
            dia.speaker,
            dia.originalAudioEmotion
          );
          dia.translatedAudioDurationTemp = null;
          dia.record = false;
          dia.translations = checkLanguage(dia.translations);
          dia.filterShow = true;
          dia.loader = false;
          dia.tempAudioList = findTransObjectsbyLangCode(dia.translations)
            ?.translatedAudio
            ? [
              {
                url: findTransObjectsbyLangCode(dia.translations)
                  ?.translatedAudio,
                length: findTransObjectsbyLangCode(dia.translations)
                  ?.translatedAudioDuration,
                emotion: "original",
              },
            ]
            : [];
        });

        setAudioData(dialogues);
        setLoader(false);
      } catch (err) {
        console.log(err);
        setLoader(false);
      }
    };
    fetchData();
  }, []);

  //continuous feature
  useEffect(() => {
    if (continuousSync) {
      if (currentContinuousSync) {
        if (currentContinuousSync.keepOriginalText) {
          if (originalAudioRefs.current[currentContinuousSync.id]) {
            originalAudioRefs.current[
              currentContinuousSync.id
            ].audioEl.current.play();
          }
        } else {
          if (translatedAudioRefs.current[currentContinuousSync.id]) {
            translatedAudioRefs.current[currentContinuousSync.id].play();
          }
        }
      }
    }
  }, [currentContinuousSync]);



  // Add new state for tracking currently playing audio
  const [currentlyPlayingId, setCurrentlyPlayingId] = useState(null);

  // Add stopAllOtherAudio function
  const stopAllOtherAudio = (currentId) => {
    // Stop all translated audio players except current
    translatedAudioRefs.current.forEach((player, index) => {
      if (player && index !== currentId) {
        player.pause();
      }
    });

    // Stop all original audio players except current
    originalAudioRefs.current.forEach((player, index) => {
      if (player && index !== currentId && player.audioEl.current) {
        player.audioEl.current.pause();
      }
    });
  };

  // Add cleanup useEffect
  useEffect(() => {
    return () => {
      // Cleanup function to stop all audio when component unmounts
      translatedAudioRefs.current.forEach((player) => {
        if (player) {
          player.pause();
        }
      });
      originalAudioRefs.current.forEach((player) => {
        if (player && player.audioEl.current) {
          player.audioEl.current.pause();
        }
      });
    };
  }, []);




  return (
    <Grid
      item
      container
      justifyContent="center"
      marginTop={4}
      style={{ fontFamily: "var(--global-text-font)" }}
    >
      <ToastContainer position="top-center" theme="dark" />
      <Loader Load={audioLoader} />
      <Loader Load={loader} />
      {/* {console.log(audioData)} */}

      <Grid item container xs={11.3} direction="column">
        <Grid item sx={{ fontWeight: "600" }} marginBottom={56}>
          Voice Quality Assurance
        </Grid>
        <Grid item container direction="row">
          <VideoSliderHorizontal
            source={projectFile.videoUrl}
            seekTime={seekTime}
            displayAudioTime={displayAudioTime}
            seekTimeIndex={seekTimeIndex}
            continuousSync={continuousSync}
            setContinuousSync={setContinuousSync}
            playPausePlayer={playPausePlayer}
            setTranslationSync={setTranslationSync}
            translationSync={translationSync}
            goToPageAccordingTimeAPI={goToPageAccordingTimeAPI}
          />

          <Grid item container xs={12}>
            <Grid
              item
              container
              className={classes["AudioWorkPlace"]}
              //   marginTop={1}
              direction="column"
              height="1520px"
            >
              <Grid item container direction="row">
                <Grid item xs={1.1} bgcolor="#EBEBEB">
                  <Grid
                    item
                    container
                    marginTop={1}
                    marginBottom={1}
                    sx={{
                      color: "black",
                      fontFamily: "var(--global-text-font)",
                      fontSize: "13px",
                    }}
                    direction="row"
                    justifyContent="space-evenly"
                  >
                    <Grid item>{getLanguageName(projectFile.orginalLang)}</Grid>
                    <Grid
                      item
                      sx={{
                        color: `${secondaryColor}`,
                      }}
                    >
                      <TranslateIcon fontSize="small" />
                    </Grid>
                    <Grid item> {getLanguageName(languageCode)}</Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={10.76}>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    sx={{
                      bgcolor: `${secondaryColor}`,
                      color: `${primaryColor}`,
                      fontWeight: 600,
                    }}
                    paddingLeft={2}
                    paddingRight={2}
                  // justifyContent="space-between"
                  >
                    <Grid item xs={3} container justifyContent="center">
                      <Grid item>Original</Grid>
                    </Grid>

                    <Grid item xs={3.3} container justifyContent="center">
                      <Grid item>Translation</Grid>
                    </Grid>

                    <Grid item xs={2} container justifyContent="center">
                      <Grid item>New audio</Grid>
                    </Grid>
                    <Grid item xs={2} container justifyContent="center">
                      <Grid item>Notes</Grid>
                    </Grid>
                    <Grid item xs={1.5} container justifyContent="center">
                      <Grid item>Rating</Grid>
                    </Grid>
                    {/* <Grid item xs={2} container justifyContent="center">
                      <Grid item>Voice</Grid>
                    </Grid> */}
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  direction="row"
                  marginBottom={4}
                  marginTop={2}
                  className={classes["AudioWorkPlaceChatBoxScroller"]}
                  // maxHeight={{
                  //   xl: "800px",
                  //   lg: "550px",
                  //   md: "800px",
                  //   xs: "560px",
                  // }}
                  // height="580px"
                  ref={containerRef}
                >
                  {audioData ? (
                    <>
                      {audioData.map((data, index) => (
                        <React.Fragment key={index}>
                          <Grid
                            item
                            container
                            direction="row"
                            // justifyContent="space-between"
                            marginBottom={0.5}
                          >
                            <Grid
                              xs={1.0}
                              item
                              container
                              direction="row"
                              sx={{
                                backgroundColor: `${secondaryColor}`,
                                height: "120px",
                                borderTopLeftRadius: "10px",
                                borderBottomLeftRadius: "10px",
                              }}
                              marginLeft={2}
                              // paddingTop={0}
                              alignContent="center"
                              justifyContent="center"
                              gap={1.5}
                            >
                              <Grid
                                item
                                container
                                justifyContent="center"
                                height="25px"
                              >
                                {data.speaker ? (
                                  <Grid>
                                    <Select
                                      value={data.speaker}
                                      onChange={(e) =>
                                        handleSpeakerChange(e, data.id)
                                      }
                                      input={<BootstrapInputForAvalialbe />}
                                    >
                                      {characters?.map((char, index) => (
                                        <MenuItem
                                          key={index}
                                          value={char.speaker}
                                        >
                                          {findDisplayName(char.speaker)}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </Grid>
                                ) : (
                                  <Grid
                                    // width="100px"
                                    // height="22px"
                                    marginBottom={1}
                                  >
                                    <Select
                                      value={data.speaker}
                                      onChange={(e) =>
                                        handleSpeakerChange(e, data.id)
                                      }
                                      input={<BootstrapInput />}
                                    >
                                      {characters?.map((char) => (
                                        <MenuItem
                                          key={char.speaker}
                                          value={char.speaker}
                                        >
                                          {findDisplayName(char.speaker)}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </Grid>
                                )}
                              </Grid>

                              <Grid item>
                                <InputMask
                                  mask="99:99:99.99"
                                  value={inputStartTime[index] || ""}
                                  onChange={(e) => handleStartTimeChange(e, index)}
                                  className={classes["AudioWorkPlaceTimelineEditTranslation"]}
                                  placeholder="HH:MM:SS.MS"
                                >
                                  {(inputProps) => (
                                    <InputBase {...inputProps} fullWidth style={{ padding: "8px" }} />
                                  )}
                                </InputMask>
                              </Grid>

                              <Grid item>
                                <InputMask
                                  mask="99:99:99.99"
                                  value={inputEndTime[index] || ""}
                                  onChange={(e) => handleEndTimeChange(e, index)}
                                  className={classes["AudioWorkPlaceTimelineEditTranslation"]}
                                  placeholder="HH:MM:SS.MS"
                                >
                                  {(inputProps) => (
                                    <InputBase {...inputProps} fullWidth style={{ padding: "8px" }} />
                                  )}
                                </InputMask>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              container
                              className={classes["AudioWorkPlaceBoxes"]}
                              direction="row"
                              paddingLeft={2}
                              paddingRight={2}
                              paddingTop={1}
                              justifyContent="space-between"
                              xs={10.76}
                              onClick={() => {
                                setSeekTime(data.start);
                                setSeekTimeIndex(seekTimeIndex + 1);
                              }}
                              sx={{ cursor: "pointer" }}
                              height="120px"
                            >
                              <Grid xs={3} item container direction="column">
                                <Grid item marginTop={2.5}>
                                  <Grid item fontSize={13} color="green">
                                    Duration:{" "}
                                    {/* {data.originalAudioDuration
                                      ? data.originalAudioDuration
                                      : data.end - data.start} */}
                                    {(data.end - data.start).toFixed(3)}
                                  </Grid>
                                  <ReactAudioPlayer
                                    style={{
                                      width: "80%",
                                      height: "20px",
                                    }}
                                    ref={(el) => (originalAudioRefs.current[index] = el)}
                                    src={data.originalAudio}
                                    controls
                                    onPlay={() => {
                                      stopAllOtherAudio(index);
                                      setCurrentlyPlayingId(`original-${index}`);
                                    }}
                                  />
                                </Grid>

                                <Grid item>
                                  <textarea
                                    className={
                                      classes["AudioWorkTextEditInput"]
                                    }
                                    style={{
                                      color:
                                        currentTime >= data.start &&
                                          currentTime < data.end
                                          ? "red"
                                          : "",
                                    }}
                                    value={data.text}
                                    type="text"
                                    onChange={(e) =>
                                      handleTextChange(e, data.id)
                                    }
                                  ></textarea>
                                </Grid>
                              </Grid>
                              <Grid xs={3} item container direction="row">
                                {data.keepOriginalText ? (
                                  <></>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      container
                                      direction="column"
                                    // alignContent="center"
                                    >
                                      <Grid>
                                        <Grid
                                          item
                                          fontSize={13}
                                          color="green"
                                          marginLeft={3.5}
                                        >
                                          <Grid item>
                                            {/* data.originalAudioDuration
                                            ? data.originalAudioDuration
                                            : data.end - data.start, */}
                                            {checkTranslationLength(
                                              data.end - data.start,
                                              findTransObjectsbyLangCode(
                                                data?.translations
                                              )?.translatedAudioDuration
                                                ? findTransObjectsbyLangCode(
                                                  data?.translations
                                                )?.translatedAudioDuration
                                                : data?.translatedAudioDurationTemp
                                            )}
                                          </Grid>
                                        </Grid>
                                        <Grid
                                          item
                                          fontSize={13}
                                          color="green"
                                          container
                                          direction="row"
                                          gap={1}
                                        >
                                          <Grid item>
                                            <select
                                              className={
                                                classes["AudioVolumeSelector"]
                                              }
                                              value={
                                                findTransObjectsbyLangCode(
                                                  data?.translations
                                                )?.translatedAudioVolume || ""
                                              }
                                              onChange={(e) => {
                                                handleTranslatedAudioVolume(
                                                  e,
                                                  data.id
                                                );
                                              }}
                                            >
                                              <option value="">
                                                select added volume
                                              </option>
                                              <option value={null}>
                                                Automatic Volume
                                              </option>
                                              <option value={0}>
                                                Raw Volume
                                              </option>
                                              {Array.from(
                                                { length: 21 },
                                                (_, i) => i - 10
                                              ).map((volume) => (
                                                <option
                                                  key={volume}
                                                  value={volume}
                                                >
                                                  {volume} dB
                                                </option>
                                              ))}
                                            </select>
                                          </Grid>
                                          <Grid item>
                                            {"Duration: "}
                                            {findTransObjectsbyLangCode(
                                              data.translations
                                            ).translatedAudio
                                              ? findTransObjectsbyLangCode(
                                                data.translations
                                              ).translatedAudioDuration !==
                                                undefined
                                                ? findTransObjectsbyLangCode(
                                                  data.translations
                                                ).translatedAudioDuration
                                                : data.translatedAudioDurationTemp
                                              : "no audio"}
                                            {handleVoiceOverLapping(
                                              data.id,
                                              findTransObjectsbyLangCode(
                                                data.translations
                                              ).translatedAudio
                                                ? findTransObjectsbyLangCode(
                                                  data.translations
                                                ).translatedAudioDuration
                                                : null
                                            )}
                                            {" | "}
                                            {findTransObjectsbyLangCode(
                                              data.translations
                                            ).translatedAudioVolume === null ||
                                              findTransObjectsbyLangCode(
                                                data.translations
                                              ).translatedAudioVolume ===
                                              undefined
                                              ? "Volume: original"
                                              : "Volume: " +
                                              findTransObjectsbyLangCode(
                                                data.translations
                                              ).translatedAudioVolume +
                                              " dB"}
                                          </Grid>
                                        </Grid>
                                        <GlobalAudioPlayer
                                          ref={(el) => (translatedAudioRefs.current[index] = el)}
                                          src={findTransObjectsbyLangCode(data.translations)?.translatedAudio}
                                          decible={findTransObjectsbyLangCode(data.translations).translatedAudioVolume}
                                          onPlay={() => {
                                            if (translationSync) {
                                              setSeekTime(data.start);
                                              setSeekTimeIndex(seekTimeIndex + 1);
                                              setPlayPausePlayer("pause");
                                            }
                                            stopAllOtherAudio(index);
                                            setCurrentlyPlayingId(`translated-${index}`);
                                          }}
                                          onEnd={() => {
                                            if (translationSync) {
                                              setPlayPausePlayer("play");
                                            }
                                            setCurrentlyPlayingId(null);
                                          }}
                                          onPause={() => {
                                            setCurrentlyPlayingId(null);
                                          }}
                                        />

                                        {/* <ReactAudioPlayer
                                          ref={(el) =>
                                            (translatedAudioRefs.current[
                                              index
                                            ] = el)
                                          }
                                          style={{
                                            width: "80%",
                                            height: "20px",
                                          }}
                                          src={
                                            findTransObjectsbyLangCode(
                                              data.translations
                                            )?.translatedAudio
                                          }
                                          onPlay={() => {
                                            if (translationSync) {
                                              setSeekTime(data.start);
                                              setSeekTimeIndex(
                                                seekTimeIndex + 1
                                              );
                                              setPlayPausePlayer("pause");
                                            }
                                          }}
                                          onPause={() => {
                                            if (translationSync) {
                                              setPlayPausePlayer("play");
                                            }
                                          }}
                                          controls
                                        /> */}
                                      </Grid>
                                      <Grid item marginTop={0.6}>
                                        <textarea
                                          className={
                                            classes["AudioWorkTextEditInput"]
                                          }
                                          style={{
                                            color:
                                              currentTime >= data.start &&
                                                currentTime < data.end
                                                ? "red"
                                                : "",
                                          }}
                                          value={
                                            findTransObjectsbyLangCode(
                                              data.translations
                                            ).translatedText
                                          }
                                          type="text"
                                          onChange={(e) =>
                                            handleTranslationChange(e, data.id)
                                          }
                                        ></textarea>
                                      </Grid>
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                              <Grid
                                xs={2.4}
                                item
                                container
                                direction="column"
                                gap={1}
                              // bgcolor="red"
                              >
                                <Grid
                                  item
                                  container
                                  alignContent="center"
                                  justifyContent="center"
                                >
                                  <Grid
                                    item
                                    container
                                    direction="row"
                                    alignContent="center"
                                    justifyContent="center"
                                    marginLeft={3}
                                    marginTop={-2}
                                  >
                                    <Grid
                                      item
                                      fontSize={14}
                                      fontWeight={500}
                                      marginTop={1.5}
                                      marginRight={1.5}
                                    >
                                      Use original
                                    </Grid>
                                    <Grid item>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={data.keepOriginalText}
                                          />
                                        }
                                        onClick={() =>
                                          handleKeepOriginalText(data.id)
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  container
                                  marginTop={-0.5}
                                  justifyContent="center"
                                  alignItems="center"
                                  alignContent="center"
                                  direction="row"
                                  gap={1}
                                >
                                  <Grid item fontSize={15}>
                                    {characters?.map((char, index) => (
                                      <React.Fragment key={index}>
                                        {char.speaker === data.speaker ? (
                                          <select
                                            className={classes["AudioWorkSelector"]}
                                            value={data.tempTransEmotion}
                                            onChange={(e) => {
                                              const updatedData = [...audioData];
                                              updatedData[data.id] = {
                                                ...updatedData[data.id],
                                                tempTransEmotion: e.target.value,
                                              };
                                              setAudioData(updatedData);
                                            }}
                                            disabled={data.keepOriginalText}
                                          >
                                            <option value="">select emotion</option>
                                            {(experimentalTraining) ? (
                                              // globalEmotions[originalAudioEmotion] || globalEmotions['neutral']
                                              // updateEmotionData(globalEmotions[originalAudioEmotion] || globalEmotions['neutral'])
                                              // Use global emotions mapping if experimental training is enabled and no character voices
                                              Object.entries(globalEmotions).map(([emotion, voiceId]) => (
                                                <option 
                                                  value={voiceId} 
                                                  key={emotion}
                                                  // selected={data.tempTransEmotion === voiceId}
                                                  selected={data.originalAudioEmotion === emotion}
                                                >
                                                  {emotion}
                                                </option>
                                              ))
                                            ) : (
                                              // Use character-specific voices
                                              char.voices.map((voice, index) => (
                                                <option value={voice.voice_id} key={index}>
                                                  {voice.emotion}
                                                </option>
                                              ))
                                            )}
                                          </select>
                                        ) : (
                                          <></>
                                        )}
                                      </React.Fragment>
                                    ))}
                                  </Grid>
                                  <Grid item xs={1.5}>
                                    <Grid
                                      item
                                      sx={{
                                        cursor: "pointer",

                                        color: data.keepOriginalText
                                          ? "grey"
                                          : `${secondaryColor}`,
                                        "&:hover": {
                                          color: `${primaryColor}`,
                                        },
                                      }}
                                    >
                                      <Badge badgeContent={1}>
                                        {" "}
                                        <AddCircleIcon
                                          disabled={audioLoader}
                                          onClick={() => {
                                            if (!data.keepOriginalText) {
                                              if (
                                                audioData[data.id]
                                                  .tempTransEmotion === ""
                                              ) {
                                                toast.error(
                                                  "Error !! Choose an emotion"
                                                );
                                                return;
                                              }
                                              setAudioData((prevData) => {
                                                const updatedData = [
                                                  ...prevData,
                                                ];
                                                updatedData[
                                                  data.id
                                                ].loader = true;
                                                return updatedData;
                                              });

                                              handleNewAudioUrl(data.id);
                                            }
                                          }}
                                          sx={{
                                            fontSize: "2rem",
                                          }}
                                        />
                                      </Badge>
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={1.5}>
                                    <Grid
                                      item
                                      sx={{
                                        cursor: "pointer",

                                        color: data.keepOriginalText
                                          ? "grey"
                                          : `${secondaryColor}`,
                                        "&:hover": {
                                          color: `${primaryColor}`,
                                        },
                                      }}
                                    >
                                      <Badge badgeContent={3}>
                                        <AddCircleIcon
                                          disabled={audioLoader}
                                          onClick={() => {
                                            if (!data.keepOriginalText) {
                                              if (
                                                audioData[data.id]
                                                  .tempTransEmotion === ""
                                              ) {
                                                toast.error(
                                                  "Error !! Choose an emotion"
                                                );
                                                return;
                                              }
                                              setAudioData((prevData) => {
                                                const updatedData = [
                                                  ...prevData,
                                                ];
                                                updatedData[
                                                  data.id
                                                ].loader = true;
                                                return updatedData;
                                              });

                                              handleMultipleAudio(data.id);
                                            }
                                          }}
                                          sx={{
                                            fontSize: "2rem",
                                          }}
                                        />
                                      </Badge>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                  alignContent="center"
                                  direction="row"
                                  gap={0.6}
                                >
                                  <Grid item fontSize={15}>
                                    <React.Fragment key={index}>
                                      <select
                                        className={classes["AudioWorkSelector"]}
                                        value={
                                          findTransObjectsbyLangCode(
                                            data.translations
                                          )?.translatedAudio
                                        }
                                        onChange={(e) => {
                                          selectAudioOption(e, data.id, index);
                                        }}
                                        disabled={data.keepOriginalText}
                                      >
                                        <option value="">select options</option>
                                        {data.tempAudioList.map(
                                          (audio, index) => (
                                            <option
                                              value={audio.url}
                                              key={index}
                                            >
                                              {`${index} - ${audio.emotion} (${audio.length})`}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </React.Fragment>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <ClipLoader
                                      color={secondaryColor}
                                      loading={false}
                                      size={25}
                                    />
                                  </Grid>
                                  <Grid item xs={1}>
                                    <ClipLoader
                                      color={secondaryColor}
                                      loading={data.loader}
                                      size={23}
                                    />
                                  </Grid>
                                  <Grid item xs={1}>
                                    <ClipLoader
                                      color={secondaryColor}
                                      loading={false}
                                      size={25}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid
                                xs={1.5}
                                item
                                container
                                direction="column"
                                justifyContent="center"
                              >
                                <Grid item>
                                  <textarea
                                    className={
                                      classes["AudioWorkNoteEditInput"]
                                    }
                                    value={
                                      findTransObjectsbyLangCode(
                                        data.translations
                                      ).translatedAudioNote
                                        ? findTransObjectsbyLangCode(
                                          data.translations
                                        ).translatedAudioNote
                                        : ""
                                    }
                                    placeholder="add note here"
                                    type="text"
                                    onChange={(e) =>
                                      handleNoteChange(e, data.id)
                                    }
                                  ></textarea>
                                </Grid>
                              </Grid>

                              <Grid
                                xs={1.5}
                                item
                                container
                                direction="column"
                                justifyContent="center"
                              >
                                <Grid
                                  item
                                  container
                                  alignItems="center"
                                  direction="column"
                                >
                                  <Grid item>
                                    <Rating
                                      size="large"
                                      value={
                                        findTransObjectsbyLangCode(
                                          data.translations
                                        ).translatedAudioRating
                                          ? findTransObjectsbyLangCode(
                                            data.translations
                                          ).translatedAudioRating
                                          : 0
                                      }
                                      precision={1}
                                      onChange={(event, newValue) => {
                                        handleDialogueRating(newValue, data.id);
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              marginTop={2}
            >
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                gap={2}
              >
                <Grid item>
                  <TaskButton
                    onClick={() => nextPrevPageAPI("previous")}
                    width="80px"
                    disabled={Number(pageNumber) === 1 || pageButtonActivity}
                  >
                    Previous
                  </TaskButton>
                </Grid>

                <Grid item>
                  <span>
                    Page {pageNumber} of {totalPages}
                  </span>
                </Grid>

                <Grid item>
                  <TaskButton
                    width="80px"
                    onClick={() => nextPrevPageAPI("next")}
                    disabled={
                      Number(pageNumber) === totalPages || pageButtonActivity
                    }
                  >
                    Next
                  </TaskButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              gap={4}
              marginTop={1}
              marginLeft={15.5}
            >
              <Grid item>
                <input
                  type="text"
                  style={{ width: "40px" }}
                  value={goToPageNumber}
                  // placeholder="Enter the page number "
                  onChange={(e) => {
                    setGoToPageNumber(e.target.value >= 1 ? e.target.value : 1);
                  }}
                />
              </Grid>
              <Grid item>
                <TaskButton
                  onClick={() => goToPageAPI(goToPageNumber)}
                  width="80px"
                >
                  Go
                </TaskButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            marginTop={2}
            gap={3}
          >
            <Grid item xs={1}>
              <input
                style={{ width: "150px", height: "20px" }}
                className={classes["AudioWorkNoteEditInput"]}
                type="text"
                placeholder="Enter version name"
                value={versionName}
                onChange={(e) => setVersionName(formatInputs(e.target.value))}
              />
            </Grid>
            <Grid item>
              <TaskButton
                onClick={() => finalSubmission()}
              // disabled={true}
              >
                Submit All
              </TaskButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VoiceQAScreen;