import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../Assets/Styles/Global.module.css";
import Loader from "../Components/Loader.js";
import { GET_USERS, UPDATE_PROJECT, FACE_DETECTOR, GET_PROJECT_VERSION } from "../MProjectConst.js";
import { getProjectById } from "../MProject-services.proxy.js";
import { languagesCode } from "../Data/LanguagesCode.js";
import DeleteIcon from "@mui/icons-material/Delete";
import { getLanguageName, getTaskName } from "../utils/generalFunctions.js";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// import { ConfirmToast } from "react-confirm-toast";
import { toast } from "react-toastify";
import UserSelectAutocomplete from "../Components/UserSelectAutoComplete/index.js";

const ProjectEditScreen = () => {
  const params = useParams();
  const { id: projectId } = params;
  const navigate = useNavigate();
  const [videoFile, setVideoFile] = useState("");
  const [videoURL, setVideoURL] = useState();
  const [dialogueAudioFile, setDialogueAudioFile] = useState("");
  // const [dialogueAudioURL, setDialogueAudioURL] = useState();
  const [fxFile, setFxFile] = useState("");

  const [employes, setEmployes] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectOrginalLang, setProjectOrginalLang] =
    useState("Original Language");
  const [projectFinalLang, setProjectFinalLang] = useState("Final Languages");
  const [projectFinalLangs, setProjectFinalLangs] = useState([]);
  const [uploadedAudioTypes, setUploadedAudioTypes] = useState([]);

  const [selectEmployee, setSelectEmployee] = useState("select an to add user");
  const [selectRole, setSelectRole] = useState(
    "select the role of the employee"
  );
  const [displaySelection, setDisplaySelection] = useState([]);
  const [upLoader, setUpLoader] = useState(false);
  const [newOldFile, setNewOldFile] = useState(true);
  const [facesFileExists, setFacesFileExists] = useState(false);
  const [isFaceDetecting, setIsFaceDetecting] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedUserObj, setSelectedUserObj] = useState(null);  // Add this state
  const [projectVersion, setProjectVersion] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await axios.get(
        //   `http://localhost:4001/api/projects/${projectId}`
        // );

        const response = await getProjectById(projectId);

        setProjectName(response.data.name);
        setProjectOrginalLang(response.data.orginalLang);
        setProjectFinalLangs(response.data.finalLang);
        setDisplaySelection(response.data.employes);
        setVideoFile(response.data.videoUrl);
        setDialogueAudioFile(response.data.dialogueAudioUrl);
        setFxFile(response.data.fxUrl);
        setUploadedAudioTypes(response.data.uploadedAudioTypes);
        setFacesFileExists(response.data.facesFileExists);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [projectId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const employes = await axios.post(GET_USERS);
        setEmployes(employes.data.usersArray);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const handleUpdateProject = async (e) => {
    setUpLoader(true);
    e.preventDefault();

    if (newOldFile) {
      try {
        // const response =
        await axios.post(UPDATE_PROJECT, {
          name: projectName,
          employes: displaySelection,
          orginalLang: projectOrginalLang,
          finalLang: projectFinalLangs,
          videoUrl: videoFile,
          dialogueAudioUrl: dialogueAudioFile,
          uploadedAudioTypes: uploadedAudioTypes,
          fxUrl: fxFile,
          id: projectId,
        });
        // console.log(response.data.msg);
        navigate("/projectlist");
      } catch (error) {
        console.log(error);
      }
    } else {
      if (!videoURL) {
        alert("He should Upload the video and transcribe it first");
        setUpLoader(false);
        return;
      }
      try {
        await axios.post(UPDATE_PROJECT, {
          employes: displaySelection,
          name: projectName,
          orginalLang: projectOrginalLang,
          finalLang: projectFinalLang,
          videoUrl: videoFile,
          dialogueAudioUrl: dialogueAudioFile,
          fxUrl: fxFile,
          uploadedAudioTypes: uploadedAudioTypes,
          id: projectId,
        });
        console.log("Project has been added");
        navigate("/adminlanding");
        setUpLoader(false);
      } catch (error) {
        console.log(error);
        setUpLoader(false);
      }
    }
  };

  const handleRemoveEmployes = (name) => {
    const newItems = displaySelection.filter((item) => item.name !== name);
    setDisplaySelection(newItems);
  };

  const handleRemoveLanguage = (code) => {
    const newItems = projectFinalLangs.filter(
      (languageCode) => languageCode !== code
    );
    setProjectFinalLangs(newItems);
  };

  const handleFacesFileAction = () => {
    const TOAST_ID = 'faces-file-confirmation';

    if (toast.isActive(TOAST_ID)) return;

    toast.info(
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '100%' }}>
        <p style={{ width: '100%', margin: '0 0 8px 0' }}>You are starting the lip sync and face detection process. Are you sure you want to proceed?</p>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            handleFaceDetectionStart();
            toast.dismiss(TOAST_ID);
          }}
        >
          Confirm
        </Button>
      </div>,
      {
        toastId: TOAST_ID,
        autoClose: false,
        closeOnClick: false,
        position: "top-center",
      }
    );
  };

  const handleFaceDetectionStart = async () => {
    try {
      setIsFaceDetecting(true);

      await axios.post(FACE_DETECTOR, {
        videoUrl: videoFile, // using videoFile instead of videoURL since that's what's in the response
        projectName: projectName,
      });

      setFacesFileExists(true);

      toast.success('Face detection completed successfully');

    } catch (error) {
      console.error('Face detection error:', error);
      toast.error('Failed to process face detection');
      // Reset checkbox in case of error
      setFacesFileExists(false);
    } finally {
      setIsFaceDetecting(false);
    }
  };

  useEffect(() => {
    const fetchProjectVersion = async () => {
      try {
        const response = await axios.post(GET_PROJECT_VERSION, {
          projectId: projectId
        });
        setProjectVersion(response.data.version);
      } catch (error) {
        console.log('Error fetching project version:', error);
      }
    };

    fetchProjectVersion();
  }, [projectId]);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      setIsAdmin(userInfo.isAdmin); // Assuming you have an `isAdmin` state
    }
  }, []);


  return (
    <Grid
      item
      container
      justifyContent="center"
      style={{ fontFamily: "var(--global-text-font)" }}
    >
      <Loader Load={upLoader} />
      <ToastContainer position="top-center" theme="dark" />

      <Grid item container xs={7} marginTop={7} direction="column">
        <form onSubmit={handleUpdateProject}>
          <Grid item marginBottom={5} xs={3.1} className={classes["header"]}>
            Update Project
            {isAdmin && projectVersion && ` (Version ${projectVersion})`}
          </Grid>


          <Grid item xs={8} marginBottom={3}>
            <TextField
              width="100%"
              type="text"
              value={projectName}
            // onChange={(e) => setProjectName(e.target.value)}
            />
          </Grid>

          <Grid item xs={8} marginBottom={3}>
            <FormControl style={{ width: "220px" }}>
              <Select
                value={projectOrginalLang}
                onChange={(e) => setProjectOrginalLang(e.target.value)}
              >
                <MenuItem value="Original Language" disabled={true}>
                  Original Language
                </MenuItem>
                {languagesCode.map((language) => (
                  <MenuItem key={language.code} value={language.code}>
                    {language.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            container
            xs={12}
            marginBottom={3}
            alignItems="center"
            direction="row"
          >
            <Grid item>
              <FormControl style={{ width: "220px" }}>
                <Select
                  value={projectFinalLang}
                  onChange={(e) => setProjectFinalLang(e.target.value)}
                >
                  <MenuItem value="Final Languages" disabled={true}>
                    Final Languages
                  </MenuItem>
                  {languagesCode.map((language) => (
                    <MenuItem key={language.code} value={language.code}>
                      {language.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item marginLeft={2} xs={1} fontSize="10px">
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  if (projectFinalLang === "") return;
                  setProjectFinalLangs([
                    ...projectFinalLangs,
                    projectFinalLang,
                  ]);
                  setProjectFinalLang("");
                }}
                size="small"
              >
                Add
              </Button>
            </Grid>

            <Grid item>
              <Grid container gap={1}>
                {projectFinalLangs.map((language, index) => (
                  <Grid
                    item
                    key={index}
                    sx={{
                      bgcolor: "lightblue",
                      minWidth: "70px",
                      borderRadius: "10px",
                    }}
                  >
                    <Grid item marginLeft={1}>
                      {getLanguageName(language)}
                    </Grid>
                    <Grid item marginLeft={2}>
                      <IconButton
                        onClick={() => handleRemoveLanguage(language)}
                        size="small"
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={8} marginBottom={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={facesFileExists}
                  disabled={facesFileExists || isFaceDetecting}
                  onChange={!facesFileExists ? handleFacesFileAction : undefined}
                />
              }
              label="Lip Sync"
            />
          </Grid>

          <Grid
            item
            container
            xs={10}
            marginBottom={3}
            gap={3}
            alignItems="center"
            marginTop={7}
          >
            <UserSelectAutocomplete
              onUserSelect={(user) => {
                setSelectEmployee(user.name);
                setSelectedUserObj(user); // Store the complete user object when selected
              }}
              existingUsers={displaySelection}
              fetchUsersUrl={GET_USERS}
            />
            <Grid item>
              <Select
                value={selectRole}
                multiline
                onChange={(event) => setSelectRole(event.target.value)}
              >
                <MenuItem value="select the role of the employee">
                  select the role of employee
                </MenuItem>
                <MenuItem value="transcriber">Transcriber</MenuItem>
                <MenuItem value="translator">Translator</MenuItem>
                <MenuItem value="performancedirection">
                  Performance Direction
                </MenuItem>
                <MenuItem value="lipqualityassurance">
                  Lips Quality Check
                </MenuItem>
                <MenuItem value="speakerfaceidentification">
                  Speaker Face Identification
                </MenuItem>
                <MenuItem value="qualityassurance">Quality Assurance</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  // if (selectEmployee === "select an to add user") return;
                  if (selectRole === "select the role of the employee") return;

                  // Use the stored user object instead of searching
                  if (!selectedUserObj) {
                    toast.error("Please select a valid employee");
                    return;
                  }

                  // Check if user is already in displaySelection
                  // const isUserAlreadySelected = displaySelection.some(
                  //   item => item.id === selectedUserObj.id
                  // );

                  // if (isUserAlreadySelected) {
                  //   toast.error("This user is already assigned to the project");
                  //   return;
                  // }

                  const newItem = {
                    name: selectedUserObj.name,
                    role: selectRole,
                    id: selectedUserObj.id,
                  };
                  setDisplaySelection([...displaySelection, newItem]);

                  // Reset selections
                  // setSelectEmployee("select an to add user");
                  setSelectRole("select the role of the employee");
                  // setSelectedUserObj(null);
                }}
              >
                Add Employee
              </Button>
            </Grid>
          </Grid>

          <Grid item container xs={10} marginBottom={3}>
            <Table sx={{ minWidth: 650, bgcolor: "white" }}>
              <TableHead bgcolor="#5570dc">
                <TableRow>
                  <TableCell>NAME</TableCell>
                  <TableCell>ROLE</TableCell>
                  <TableCell>ACTIONS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displaySelection.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{getTaskName(user.role)}</TableCell>
                    <TableCell>
                      <Button
                        color="error"
                        onClick={() => handleRemoveEmployes(user.name)}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12} marginBottom={4}>
            <Table sx={{ minWidth: 650, bgcolor: "white" }}>
              <TableHead bgcolor="#5570dc">
                <TableRow>
                  <TableCell>FILE TYPE</TableCell>
                  <TableCell>URL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Original Video</TableCell>
                  <TableCell>
                    {videoFile ? (
                      <a href={videoFile} target="_blank" rel="noopener noreferrer" style={{ color: '#1976d2' }}>
                        {videoFile}
                      </a>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Dialogue Audio</TableCell>
                  <TableCell>
                    {dialogueAudioFile ? (
                      <a href={dialogueAudioFile} target="_blank" rel="noopener noreferrer" style={{ color: '#1976d2' }}>
                        {dialogueAudioFile}
                      </a>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>FX Audio</TableCell>
                  <TableCell>
                    {fxFile ? (
                      <a href={fxFile} target="_blank" rel="noopener noreferrer" style={{ color: '#1976d2' }}>
                        {fxFile}
                      </a>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                </TableRow>
                {uploadedAudioTypes.map((audio, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {audio.audioType.charAt(0).toUpperCase() + audio.audioType.slice(1)}
                      {audio.channelType && ` (${audio.channelType} channel)`}
                      {audio.uploadType && ` - ${audio.uploadType}`}
                    </TableCell>
                    <TableCell>
                      {audio.url ? (
                        <a href={audio.url} target="_blank" rel="noopener noreferrer" style={{ color: '#1976d2' }}>
                          {audio.url}
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>

          <Button variant="contained" type="submit">
            Update
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default ProjectEditScreen;
