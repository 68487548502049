import React, { useContext, useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import classes from "../../Assets/Styles/TestingAudio.module.css";
import { formatTime, timeStringToSeconds } from "../../utils/generalFunctions";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import { Store } from "../../Store";
import {
  ADD_ONE_SOUNDEFFECTS,
  DELETE_ONE_SOUNDEFFECTS,
  GET_ALL_SOUNDEFFECTS,
  GET_SPEECH_FOR_PROJECT,
  UPDATE_ONE_SOUNDEFFECTS,
} from "../../MProjectConst";
import Loader from "../Loader";

const SoundEffectsParts = ({
  setSeekTime,
  seekTimeIndex,
  setSeekTimeIndex,
  currentTime,
}) => {
  const { state } = useContext(Store);
  const timeoutIdRef = useRef(null);
  const { projectFile, userInfo } = state;
  const [audioLoader, setAudioLoader] = useState(false);

  //   const [audioData, setAudioData] = useState([
  //     { text: "hello", start: 0, end: 5 },
  //   ]);

  const [audioData, setAudioData] = useState([]);

  const [startTimeText, setStartTimeText] = useState("00:00:00");
  const [endTimeText, setEndTimeText] = useState("00:00:00");
  const [descriptionText, setDescriptionText] = useState();

  //+ Text api for dialogue
  function handleDescriptionChange(e, index) {
    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      description: e.target.value,
    };

    setAudioData(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SOUNDEFFECTS, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 1650);
  }

  //+ Start time api for dialogue
  function handleStartTimeChange(e, index) {
    const newTime = timeStringToSeconds(e.target.value);
    if (!newTime) {
      return;
    }

    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      start: newTime,
    };

    setAudioData(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SOUNDEFFECTS, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 2300);
  }

  //+ End time api for dialogue
  function handleEndTimeChange(e, index) {
    const newTime = timeStringToSeconds(e.target.value);
    if (!newTime) {
      return;
    }

    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      end: newTime,
    };

    setAudioData(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SOUNDEFFECTS, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 2300);
  }

  //apis - adding new soundEffects
  const AddNewSoundEffect = async () => {
    if (endTimeText === "00:00:00" || descriptionText === "") {
      alert("Fill all the box");
      return;
    }
    let updatedData = [...audioData];
    try {
      const response = await axios.post(ADD_ONE_SOUNDEFFECTS, {
        projectId: projectFile.id,
        dialogue: {
          description: descriptionText,
          end: timeStringToSeconds(endTimeText),
          start: timeStringToSeconds(startTimeText),
        },
      });
      if (response.data) {
        const NewDialogue = {
          description: descriptionText,
          end: timeStringToSeconds(endTimeText),
          start: timeStringToSeconds(startTimeText),
          dbid: response.data,
        };
        const index = updatedData.findIndex(
          (obj) =>
            NewDialogue.start < obj.start ||
            (NewDialogue.start === obj.start && NewDialogue.end < obj.end)
        );
        if (index === -1) {
          updatedData.push(NewDialogue);
        } else {
          updatedData.splice(index, 0, NewDialogue); // Insert at the found index
        }
        // updatedData.push(NewDialogue);
        updatedData = updatedData.map((object, index) => ({
          ...object,
          add: false,
          id: index,
        }));
        setStartTimeText("00:00:00.00");
        setEndTimeText("00:00:00.00");
        setDescriptionText("Write here");
        setAudioData(updatedData);
      }
    } catch (err) {
      console.log(err);
      alert("Error in adding the new sound effect");
    }
  };

  //+ removing api
  const removeDialogue = async (uid) => {
    try {
      const response = await axios.post(DELETE_ONE_SOUNDEFFECTS, {
        projectId: projectFile.id,
        uid: uid,
      });
      if (response.data) {
        let newItems = audioData.filter((dia) => dia.dbid !== uid);
        newItems = newItems.map((object, index) => ({
          ...object,
          id: index,
        }));
        setAudioData(newItems);
        alert("The Dialogue has been deleted!");
      }
    } catch (err) {
      console.log(err);
      alert("Error!");
    }
  };

  //apis - dialogue
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(GET_ALL_SOUNDEFFECTS, {
          projectId: projectFile.id,
        });

        const sortedArray = await response.data.projectsArray.sort((a, b) => {
          if (a.start < b.start) return -1;
          if (a.start > b.start) return 1;
          if (a.end < b.end) return -1;
          if (a.end > b.end) return 1;

          return 0;
        });

        sortedArray.forEach((dia, index) => {
          dia.add = false;
          dia.id = index;
        });

        setAudioData(sortedArray);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Loader Load={audioLoader} />
      {audioData.length > 0 ? (
        <>
          {audioData.map((data, index) => (
            <React.Fragment key={index}>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                marginBottom={2}
              >
                <Grid
                  item
                  container
                  direction="column"
                  xs={1.8}
                  marginLeft={1}
                  className={classes["AudioWorkPlaceTimeline"]}
                >
                  <Grid item>
                    <textarea
                      className={classes["AudioWorkPlaceTimelineEdit"]}
                      style={{ height: "20px" }}
                      value={formatTime(data.start)}
                      type="text"
                      onChange={(e) => handleStartTimeChange(e, data.id)}
                    ></textarea>
                  </Grid>
                  <Grid item>
                    <textarea
                      className={classes["AudioWorkPlaceTimelineEdit"]}
                      style={{ height: "20px" }}
                      value={formatTime(data.end)}
                      type="text"
                      onChange={(e) => handleEndTimeChange(e, data.id)}
                    ></textarea>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  className={classes["AudioWorkPlaceBoxes"]}
                  direction="column"
                  height="54px"
                  // gap={1}
                  paddingLeft={1.5}
                  paddingTop={1}
                  xs={5}
                  onClick={() => {
                    setSeekTime(data.start);
                    setSeekTimeIndex(seekTimeIndex === 1 ? 2 : 1);  // Toggle between 1 and 2
                  }}
                  // sx={{ cursor: "pointer" }}

                  sx={{
                    bgcolor:
                      currentTime >= data.start && currentTime < data.end
                        ? "red"
                        : "",
                    cursor: "pointer",
                  }}
                >
                  <Grid item xs={8}>
                    <textarea
                      className={classes["AudioWorkTextEditInput"]}
                      // style={{
                      //   color:
                      //     currentTime >= data.start && currentTime < data.end
                      //       ? "red"
                      //       : "",
                      // }}
                      placeholder="Write the sound effect description"
                      value={data.description}
                      type="text"
                      onChange={(e) => handleDescriptionChange(e, data.id)}
                    ></textarea>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={2}
                  container
                  direction="row"
                  justifyItems="center"
                >
                  <Grid
                    item
                    marginTop={1}
                    sx={{ cursor: "pointer", color: "#5570DC" }}
                  >
                    <AddCircleIcon
                      onClick={() => {
                        const updatedData = [...audioData];
                        updatedData[data.id] = {
                          ...updatedData[data.id],
                          add: true,
                        };

                        setAudioData(updatedData);
                      }}
                      fontSize="large"
                    />
                  </Grid>
                  <Grid
                    item
                    fontSize="40px"
                    sx={{ cursor: "pointer", color: "red" }}
                  >
                    <DeleteIcon
                      onClick={() => removeDialogue(data.dbid)}
                      fontSize="large"
                    />
                  </Grid>
                </Grid>
              </Grid>
              {data.add ? (
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                >
                  <Grid
                    item
                    container
                    direction="column"
                    xs={2}
                    marginLeft={1.5}
                    marginBottom={1.5}
                    // justifyContent="center"
                    marginTop={1.1}
                    className={classes["AudioWorkPlaceTimeline"]}
                  >
                    <Grid color="blue" item>
                      <textarea
                        className={classes["AudioWorkPlaceTimelineEditInput"]}
                        style={{ height: "20px" }}
                        value={startTimeText}
                        type="text"
                        onChange={(e) => setStartTimeText(e.target.value)}
                      ></textarea>
                    </Grid>
                    <Grid item color="blue">
                      <textarea
                        className={classes["AudioWorkPlaceTimelineEditInput"]}
                        style={{ height: "20px" }}
                        value={endTimeText}
                        type="text"
                        onChange={(e) => setEndTimeText(e.target.value)}
                      ></textarea>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    className={classes["AudioWorkPlaceBoxes"]}
                    direction="column"
                    gap={1}
                    paddingLeft={2}
                    paddingTop={1}
                    xs={5}
                    height="54px"
                    marginRight={3}
                  >
                    <Grid item>
                      <textarea
                        className={classes["AudioWorkTextEditInput"]}
                        value={descriptionText}
                        type="text"
                        onChange={(e) => setDescriptionText(e.target.value)}
                        placeholder="Write the sound effect description"
                      ></textarea>
                    </Grid>
                  </Grid>

                  <Grid item xs={2} container direction="row">
                    <Grid
                      item
                      marginTop={1}
                      marginRight={1}
                      onClick={() => {
                        AddNewSoundEffect();
                      }}
                      sx={{
                        cursor: "pointer",
                        color: "#5570DC",
                      }}
                    >
                      <SaveIcon fontSize="large" />
                    </Grid>
                    <Grid
                      item
                      fontSize="40px"
                      sx={{
                        cursor: "pointer",
                        color: "red",
                      }}
                    >
                      <CancelIcon
                        onClick={() => {
                          const updatedData = [...audioData];
                          updatedData[data.id] = {
                            ...updatedData[data.id],
                            add: false,
                          };
                          setAudioData(updatedData);
                        }}
                        fontSize="large"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </React.Fragment>
          ))}
        </>
      ) : (
        <>
          <Grid item container direction="row" justifyContent="space-between">
            <Grid
              item
              container
              direction="column"
              xs={2}
              marginLeft={1.5}
              // justifyContent="center"
              marginTop={1.1}
              className={classes["AudioWorkPlaceTimeline"]}
            >
              <Grid color="blue" item>
                <textarea
                  className={classes["AudioWorkPlaceTimelineEditInput"]}
                  style={{ height: "20px" }}
                  value={startTimeText}
                  type="text"
                  onChange={(e) => setStartTimeText(e.target.value)}
                ></textarea>
              </Grid>
              <Grid item color="blue">
                <textarea
                  className={classes["AudioWorkPlaceTimelineEditInput"]}
                  style={{ height: "20px" }}
                  value={endTimeText}
                  type="text"
                  onChange={(e) => setEndTimeText(e.target.value)}
                ></textarea>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={classes["AudioWorkPlaceBoxes"]}
              direction="column"
              gap={1}
              paddingLeft={2}
              paddingTop={1}
              xs={5}
              height="54px"
            >
              <Grid item>
                <textarea
                  className={classes["AudioWorkTextEditInput"]}
                  value={descriptionText}
                  type="text"
                  onChange={(e) => setDescriptionText(e.target.value)}
                  placeholder="Write the sound effect description"
                ></textarea>
              </Grid>
            </Grid>

            <Grid item xs={2} container direction="row">
              <Grid
                item
                marginTop={1}
                marginRight={1}
                onClick={() => {
                  AddNewSoundEffect();
                }}
                sx={{
                  cursor: "pointer",
                  color: "#5570DC",
                }}
              >
                <AddCircleIcon fontSize="large" />
              </Grid>
              <Grid
                item
                fontSize="40px"
                sx={{
                  cursor: "pointer",
                  color: "red",
                }}
              >
                <CancelIcon
                  // onClick={() => {
                  //   const updatedData = [...audioData];
                  //   updatedData[data.id] = {
                  //     ...updatedData[data.id],
                  //     add: false,
                  //   };
                  //   setAudioData(updatedData);
                  // }}
                  fontSize="large"
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default SoundEffectsParts;
