import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Button
} from "@mui/material";
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import classes from "../../Assets/Styles/Transcriber.module.css";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ContactsIcon from "@mui/icons-material/Contacts";
import Loader from "../../Components/Loader";
import axios from "axios";
import { Store } from "../../Store";
import {
  ADD_SPEECH_FOR_PROJECT,
  AUDIO_CHOPPING,
  AUDIO_CHOPPING_STATUS_CHECK,
  AddUpdateDelete_CHARACTER_FOR_PROJECT,
  // CREATE_ALL_VOCALS_AUTOMATICALLY,
  // CREATE_LIST_AUDIOS_TEXTS,
  DELETE_SPEECH_FOR_PROJECT,
  GET_CHARACTERS_FOR_PROJECT,
  GET_SPEECH_FOR_PROJECT_BY_PAGE,
  TRANSLATE,
  UPDATE_ONE_SPEECH,
  // VOICE_TRAINING,
  VOICE_TRAINING_STATUS_CHECK,
  AUTOMATIC_VOICE_PROCESSING,
  VALIDATE_EMOTIONS,
  GET_SPEECH_POSITION_AROUND_TIME
} from "../../MProjectConst";
import { ToastContainer, toast } from "react-toastify";
import VideoSlider from "../../Components/AudioTools/VideoSlider";
import UnifiedVideoPlayer from "../../Components/AudioTools/UnifiedVideoPlayer";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useMediaQuery } from "@mui/material";
import joinBelow from "../../Assets/Images/joinBelow.png";
import {
  formatInputs,
  formatTime,
  getLanguageName,
  timeStringToSeconds,
} from "../../utils/generalFunctions";
import SoundEffectsParts from "../../Components/TranscriberComponents/SoundEffectsParts";
import { primaryColor, secondaryColor } from "../../utils/constants";
import TaskButton from "../../Components/Buttons/TaskButton";
import WarningToastWithOverlay from "../../Components/WarningToastWithOverlay/index.js";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    width: "0px",
    // height: "20px",
    borderRadius: 4,
    position: "relative",
    backgroundColor: "none",
    border: "none",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: `${secondaryColor}`,
    fontFamily: "Livvic",
    // "&:focus": {
    //   borderRadius: 4,
    //   borderColor: "#80bdff",
    //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    // },
  },
}));

const BootstrapInputForAvalialbe = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    color: `${secondaryColor}`,
    fontFamily: "Livvic",
    fontWeight: "600",
    width: "110px",
    position: "relative",
    backgroundColor: "none",
    border: "none",
    fontSize: 16,
  },
}));

const TranscriberScreen = () => {
  const timeoutIdRef = useRef(null);
  const screenIsOver1200px = useMediaQuery("(min-width: 1200px)");
  const { state } = useContext(Store);
  const { projectFile, userInfo } = state;
  const [audioData, setAudioData] = useState([]);
  const [openCharactersFolder, setOpenCharactersFolder] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [seekTime, setSeekTime] = useState(0);
  const [seekTimeIndex, setSeekTimeIndex] = useState(0);
  const [scrollableItem, setScrollableItem] = useState(0);
  const [scrollableItemLastUpdated, setScrollableItemLastUpdated] = useState(0);
  const [pausePlay, setPausePlay] = useState(false);
  const [audioLoader, setAudioLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [startTimeText, setStartTimeText] = useState("00:00:00");
  const [endTimeText, setEndTimeText] = useState("00:00:00");
  const [speakerText, setSpeakerText] = useState("");
  const [diaText, setDiaText] = useState("");
  const [audioEmotion, setAudioEmotion] = useState("neutral");
  const [audioRating, setAudioRating] = useState(1);
  const [videoSource, setVidoSource] = useState(projectFile.videoUrl);
  const audioSource = projectFile.dialogueAudioUrl;
  const numberOfDialogues = projectFile.numberOfDialogues;

  const [workPlaceType, setWorkPlaceType] = useState(true);
  const [characters, setCharacters] = useState([]);
  const [charName, setCharName] = useState({
    speaker: "",
    voices: [],
    displayName: "",
  });
  const [checkChoppingStatus, setCheckChoppingStatus] = useState(false);

  // pagination items
  const [pageButtonActivity, setPageButtonActivity] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [goToPageNumber, setGoToPageNumber] = useState();
  const itemsPerPage = 10;
  const totalPages = Math.ceil(numberOfDialogues / itemsPerPage);
  const [warningShown, setWarningShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    if (pausePlay) {
      if (screenIsOver1200px) {
        if (workPlaceType) {
          scrollToItem(scrollableItem - 1);
        }
      }
    }
  }, [scrollableItem]);

  useEffect(() => {
    checkTimeInRange(currentTime);
  }, [currentTime]);

  const scrollToItem = (index) => {
    const manpIndex = index;

    if (containerRef.current) {
      const item = containerRef.current.children[manpIndex];
      if (item) {
        item.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  const displayAudioTime = useCallback((data) => {
    setCurrentTime(data);
  }, []);

  function checkTimeInRange(currentTime) {
    if (audioData) {
      const itemInTimeRange = audioData.find((item) => {
        // const { start, end } = item.textdetails;
        return currentTime >= item.start && currentTime < item.end;
      });

      if (itemInTimeRange) {
        if (itemInTimeRange.id !== scrollableItemLastUpdated) {
          setScrollableItemLastUpdated(itemInTimeRange.id);
          setScrollableItem(itemInTimeRange.id);
        }
      }
    }
  }

  function findDisplayName(speaker) {
    const result = characters.find((char) => char.speaker === speaker);

    if (result.displayName) {
      return result.displayName;
    }
    return speaker;
  }

  //+ Text api for dialogue
  function handleTextChange(e, index) {
    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      text: e.target.value,
    };

    setAudioData(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 1650);
  }
  //+ Speaker api for dialogue
  const handleSpeakerChange = async (e, index) => {
    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      speaker: e.target.value,
    };

    setAudioData(updatedData);

    try {
      const response = await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
      if (response.data) {
        setAudioData(updatedData);
      }
    } catch (err) {
      console.log(err);
      toast.error("Error in changing the speaker");
    }
  };


  //+ Start time api for dialogue
  function handleStartTimeChange(e, index) {
    const newTime = timeStringToSeconds(e.target.value);
    if (!newTime) {
      return;
    }
    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      start: newTime,
    };

    setAudioData(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 2300);
  }
  //+ End time api for dialogue
  function handleEndTimeChange(e, index) {
    const newTime = timeStringToSeconds(e.target.value);
    if (!newTime) {
      return;
    }

    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      end: newTime,
    };

    setAudioData(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 2300);
  }

  //+ face api for dialogue
  const handleFaceCheck = async (index) => {
    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      faceCheck: !updatedData[index].faceCheck,
    };

    setAudioData(updatedData);

    try {
      const response = await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
      if (response.data) {
        setAudioData(updatedData);
      }
    } catch (err) {
      console.log(err);
      toast.error("Error in checking/unchecking the face box");
    }
  };
  //+ emotion api for dialogue
  const handleDialogueEmotion = async (e, index) => {
    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      originalAudioEmotion: e.target.value,
    };

    setAudioData(updatedData);

    try {
      const response = await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
      if (response.data) {
        setAudioData(updatedData);
      }
    } catch (err) {
      console.log(err);
      toast.error("Error in changing the dialogues's emotion");
    }
  };

  //+ rating api for dialogue
  const handleDialogueRating = async (e, index) => {
    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      originalAudioRating: e.target.value,
    };

    setAudioData(updatedData);

    try {
      const response = await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
      if (response.data) {
        setAudioData(updatedData);
      }
    } catch (err) {
      console.log(err);
      toast.error("Error in changing the dialogues's rating");
    }
  };

  //+ editing api for character
  const editCharacter = async (name, index) => {
    let updatedChar = [];

    if (characters?.length > 0) {
      updatedChar = [...characters];
    }
    updatedChar[index] = {
      ...updatedChar[index],
      displayName: name,
    };

    setCharacters(updatedChar);

    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(AddUpdateDelete_CHARACTER_FOR_PROJECT, {
        projectId: projectFile.id,
        characters: updatedChar,
      });
    }, 1650);
  };

  //+ adding api for char
  const AddNewCharacter = async () => {
    if (charName.displayName === "") {
      toast.error("Enter a name");
      return;
    }

    const firstNumber = Math.floor(Math.random() * 999);
    const secondNumber = Math.floor(Math.random() * 999);
    charName.speaker = `SPEAKER_${firstNumber}${secondNumber}`;

    let updatedData = [];
    if (characters?.length > 0) {
      updatedData = [...characters];
    }

    updatedData.push(charName);

    try {
      // const response =
      await axios.post(AddUpdateDelete_CHARACTER_FOR_PROJECT, {
        projectId: projectFile.id,
        characters: updatedData,
      });

      toast.success("A new character has been added");
      setCharacters(updatedData);
      setCharName({ speaker: "", voices: [], displayName: "" });
    } catch (err) {
      console.log(err);
      toast.error("Error in adding the new Character");
    }
  };
  //+ delete api for char
  const removeCharacter = async (name) => {
    let filterChar = characters.filter((char) => char.speaker !== name);

    try {
      const response = await axios.post(AddUpdateDelete_CHARACTER_FOR_PROJECT, {
        projectId: projectFile.id,
        characters: filterChar,
      });

      if (response.data) {
        let filterChar = characters.filter((char) => char.speaker !== name);

        setCharacters(filterChar);

        toast.success("The Character has been deleted!");
      }
    } catch (err) {
      console.log(err);
      toast.error("Error in deleting the character");
    }
  };

  //+ adding api
  const AddNewDialogue = async () => {
    if (endTimeText === "00:00:00") {
      toast.error("Enter The Ending time for this sentence");
      return;
    }

    let updatedData = [...audioData];

    const translations = [];
    projectFile.finalLang?.forEach((langCode) => {
      translations.push({
        languageCode: langCode,
        translatedText: "",
        translatedAudioList: [],
        translatedAudio: "",
        translatedAudioRating: "",
        translatedAudioNote: null,
        translatedAudioPath: null,
        translatedRecording: "",
      });
    });

    try {
      const response = await axios.post(ADD_SPEECH_FOR_PROJECT, {
        projectId: projectFile.id,
        dialogue: {
          speaker: speakerText,
          text: diaText,
          end: timeStringToSeconds(endTimeText),
          start: timeStringToSeconds(startTimeText),
          originalAudioEmotion: audioEmotion,
          originalAudioRating: audioRating,
        },
      });
      if (response.data) {
        const NewDialogue = {
          speaker: speakerText,
          text: diaText,
          end: timeStringToSeconds(endTimeText),
          start: timeStringToSeconds(startTimeText),
          dbid: response.data,
          translations: translations,
          originalAudioEmotion: audioEmotion,
          originalAudioRating: audioRating,
        };

        const index = updatedData.findIndex(
          (obj) =>
            NewDialogue.start < obj.start ||
            (NewDialogue.start === obj.start && NewDialogue.end < obj.end)
        );

        if (index === -1) {
          updatedData.push(NewDialogue);
        } else {
          updatedData.splice(index, 0, NewDialogue); // Insert at the found index
        }

        // updatedData.push(NewDialogue);

        updatedData = updatedData.map((object, index) => ({
          ...object,
          add: false,
          id: index,
        }));

        toast.success("A new dialogue has been added");

        setStartTimeText("00:00:00");
        setEndTimeText("00:00:00");
        setSpeakerText("");
        setDiaText("Write here");
        setAudioEmotion("neutral");
        setAudioRating("1");

        setAudioData(updatedData);
      }
    } catch (err) {
      console.log(err);
      toast.error("Error in adding the new dialogue");
    }
  };

  //+ Merging api
  const MergeDialogues = async (index) => {
    setAudioLoader(true);
    let updatedData = [...audioData];

    if (updatedData[index].speaker !== updatedData[index + 1].speaker) {
      toast.error("Make sure both of dialogues have same character");
      setAudioLoader(false);
      return;
    }

    // Merge translations
    const mergeTranslations = (t1, t2) => {
      return {
        ...t1,
        translatedText: t1.translatedText + " " + t2.translatedText,
        translatedAudioDuration:
          (t1.translatedAudioDuration || 0) + (t2.translatedAudioDuration || 0),
      };
    };

    // Merge translations
    const mergedTranslations = updatedData[index].translations.map((t1) => {
      const matchingT2 = updatedData[index + 1].translations.find(
        (t2) => t2.languageCode === t1.languageCode
      );
      return matchingT2 ? mergeTranslations(t1, matchingT2) : t1;
    });

    // Add any translations from the second dialogue that weren't in the first
    updatedData[index + 1].translations.forEach((t2) => {
      if (!mergedTranslations.some((t) => t.languageCode === t2.languageCode)) {
        mergedTranslations.push(t2);
      }
    });

    const NewDialogue = {
      speaker: updatedData[index].speaker,
      text: updatedData[index].text + " " + updatedData[index + 1].text,
      end: updatedData[index + 1].end,
      start: updatedData[index].start,
      faceCheck: updatedData[index].faceCheck,
      originalAudioEmotion: updatedData[index].originalAudioEmotion,
      originalAudioRating: updatedData[index].originalAudioRating,
      keepOriginalText: updatedData[index].keepOriginalText
        ? updatedData[index].keepOriginalText
        : false,
      finalNote: updatedData[index].finalNote
        ? updatedData[index].finalNote
        : null,
      creationDate: updatedData[index].creationDate
        ? updatedData[index].creationDate
        : null,
      translations: mergedTranslations,
    };

    try {
      const response = await axios.post(ADD_SPEECH_FOR_PROJECT, {
        projectId: projectFile.id,
        dialogue: NewDialogue,
      });
      NewDialogue.dbid = response.data;
      if (response.data) {
        await axios.post(DELETE_SPEECH_FOR_PROJECT, {
          projectId: projectFile.id,
          uid: updatedData[index].dbid,
        });
        await axios.post(DELETE_SPEECH_FOR_PROJECT, {
          projectId: projectFile.id,
          uid: updatedData[index + 1].dbid,
        });

        const indexNumber = updatedData.findIndex(
          (obj) =>
            NewDialogue.start < obj.start ||
            (NewDialogue.start === obj.start && NewDialogue.end < obj.end)
        );

        updatedData = updatedData.filter(
          (dia) =>
            dia.dbid !== updatedData[index].dbid &&
            dia.dbid !== updatedData[index + 1].dbid
        );

        if (indexNumber === -1) {
          updatedData.push(NewDialogue);
        } else {
          updatedData.splice(indexNumber, 0, NewDialogue); // Insert at the found indexNumber
        }

        updatedData = updatedData.sort((a, b) => {
          if (a.start < b.start) return -1;
          if (a.start > b.start) return 1;
          if (a.end < b.end) return -1;
          if (a.end > b.end) return 1;

          return 0;
        });

        updatedData = updatedData.map((object, index) => ({
          ...object,
          id: index,
        }));

        setAudioData(updatedData);
        setAudioLoader(false);
        toast.success("Dialogues has been merged");
      }
    } catch (err) {
      console.log(err);
      toast.error("Error in adding the new dialogue");
      setAudioLoader(false);
    }
  };

  //+ delete api
  const removeDialogue = async (uid) => {
    try {
      const response = await axios.post(DELETE_SPEECH_FOR_PROJECT, {
        projectId: projectFile.id,
        uid: uid,
      });

      if (response.data) {
        let newItems = audioData.filter((dia) => dia.dbid !== uid);
        newItems = newItems.map((object, index) => ({
          ...object,
          id: index,
        }));
        setAudioData(newItems);

        toast.success("The dialogue has been deleted!");
      }
    } catch (err) {
      console.log(err);
      toast.error("Error in deleting the dialogue");
    }
  };

  async function audioChopping() {
    try {
      const response = await axios.post(AUDIO_CHOPPING, {
        projectId: projectFile.id,
      });

      toast.success("The Movie original audio has been chopped");
      console.log(response.data.result);
      if (response.data.result) {
        // audioTraining();
      }

      // setCheckChoppingStatus(true);
    } catch (error) {
      toast.error("An Error has occured while Chopping");
    }
  }

  async function translate() {
    for (const language of projectFile.finalLang) {
      try {
        const response = await axios.post(TRANSLATE, {
          projectId: projectFile.id,
          newLanguage: language,
        });

        toast.success(
          "Movie has been translated to " + getLanguageName(language)
        );
        console.log(await response.data.result);
        // resolve(response.data.result);
      } catch (error) {
        toast.error("An Error has occured while Translating");
      }
    }
  }

  // async function createListOfAudiosAndText() {
  //   try {
  //     const response = await axios.post(CREATE_LIST_AUDIOS_TEXTS, {
  //       projectId: projectFile.id,
  //       baseDirectory: projectFile.name,
  //     });

  //     toast.success(
  //       "The process of generating the list of drop downs has been activated"
  //     );
  //     console.log(await response.data.result);
  //     // resolve(response.data.result);
  //   } catch (error) {
  //     toast.error("An Error has occured while Translating");
  //   }
  // }

  // async function audioTraining() {
  //   if (!characters[0]?.voices?.length > 0) {
  //     try {
  //       const response = await axios.post(VOICE_TRAINING, {
  //         projectId: projectFile.id,
  //       });
  //       toast.success("The Movie voice id has been trained and added");
  //       // setcheckAudioTrainingStatus(true);
  //     } catch (error) {
  //       toast.error("An Error has occured while training the voices");
  //     }
  //   }
  // }

  // async function generateVocals() {
  //   for (const language of projectFile.finalLang) {
  //     try {
  //       const response = await axios.post(CREATE_ALL_VOCALS_AUTOMATICALLY, {
  //         projectId: projectFile.id,
  //         language: language,
  //         projectName: projectFile.name,
  //       });

  //       toast.success(
  //         "Translated vocals has been added for  all dialogues " +
  //           getLanguageName(language)
  //       );
  //     } catch (error) {
  //       toast.error("An Error has occured while Translating");
  //     }
  //   }
  // }


  const finalSubmission = async () => {
    console.log(isConfirmationOpen)
    if (loading || isConfirmationOpen) return;

    setIsConfirmationOpen(true);
    setLoading(true);

    try {
      console.log("Starting validation for project:", projectFile.id);

      const validationResponse = await axios.post(VALIDATE_EMOTIONS, {
        projectId: projectFile.id,
      });
      setIsConfirmationOpen(true);
      console.log("Validation Response:", validationResponse.data);

      if (validationResponse.data.warnings && !warningShown) {
        const TOAST_ID = "voice-processing-confirmation";

        if (toast.isActive(TOAST_ID)) return;

        toast(
          <WarningToastWithOverlay
            message={validationResponse.data.warnings}
            toastId={TOAST_ID}
            onConfirm={async () => {
              await proceedWithProcessing();
              setWarningShown(true);
              setIsConfirmationOpen(false);
            }}
          />,
          {
            toastId: TOAST_ID,
            autoClose: false,
            closeOnClick: false,
            draggable: false,
            position: "top-center",
            onClose: () => setIsConfirmationOpen(false),
            style: {
              background: 'transparent',
              boxShadow: 'none',
              padding: 0,
              minHeight: 'unset',
              width: '400px'
            }
          }
        );
      } else {
        await proceedWithProcessing();
        setIsConfirmationOpen(false);
      }
    } catch (err) {
      console.error("Error during validation:", err);
      const errorMessage =
        err.response && err.response.status === 400
          ? err.response.data.details ||
          "Error validating voice data. Please check dialogue ratings."
          : err.message;
      toast.error(`Error in validation: ${errorMessage}`);
      setIsConfirmationOpen(false); // Reset confirmation state on error
    } finally {
      setLoading(false);
    }
  };


  const proceedWithProcessing = async () => {
    try {
      toast.info("Processing voice data...");

      setLoading(true); // Start loading

      // Call processing endpoint
      const processingResponse = await axios.post(
        AUTOMATIC_VOICE_PROCESSING,
        {
          projectId: projectFile.id,
        }
      );

      console.log("Processing Response:", processingResponse.data);

      // Show success toast
      toast.success("Voice processing completed successfully");
    } catch (err) {
      console.error("Error during processing:", err);

      if (err.response) {
        console.error("Error response:", {
          status: err.response.status,
          data: err.response.data,
          headers: err.response.headers,
        });
      } else if (err.request) {
        console.error("Error request:", err.request);
      } else {
        console.error("Error message:", err.message);
      }

      // Handle specific error case for 400 status or general error
      const errorMessage =
        err.response && err.response.status === 400
          ? err.response.data.details ||
          "Error processing voice data. Please check dialogue ratings."
          : err.message;

      toast.error(`Error in processing: ${errorMessage}`);
    } finally {
      setLoading(false); // End loading
    }
  };


  const nextPrevPageAPI = async (action) => {
    setPageButtonActivity(true);
    if (pageNumber === 1 && action === "previous") {
      return;
    }

    try {
      const response = await axios.post(GET_SPEECH_FOR_PROJECT_BY_PAGE, {
        userId: userInfo.id,
        projectId: projectFile.id,
        action: action,
        pageName: "transcribe",
      });

      if (response.data) {
        const sortedArray = await response.data.projectsArray;
        sortedArray.forEach((dia, index) => {
          dia.add = false;
          dia.id = index;
        });
        setAudioData(sortedArray);

        if (action === "next") {
          setPageNumber(Number(pageNumber) + 1);
        } else if (action === "previous") {
          setPageNumber(Number(pageNumber) - 1);
        }
        setPageButtonActivity(false);
      }
    } catch (err) {
      console.log(err);
      toast.error("Error in getting the next page");
      setPageButtonActivity(false);
    }
  };

  const goToPageAPI = async (number) => {
    setLoader(true);

    try {
      const response = await axios.post(GET_SPEECH_FOR_PROJECT_BY_PAGE, {
        userId: userInfo.id,
        projectId: projectFile.id,
        action: "goto",
        pageNumber: number,
        pageName: "transcribe",
      });

      if (response.data) {
        const sortedArray = await response.data.projectsArray;
        sortedArray.forEach((dia, index) => {
          dia.add = false;
          dia.id = index;
        });

        setAudioData(sortedArray);
        setPageNumber(number);
        setGoToPageNumber("");
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
      toast.error("Error in getting the next page");
    }
  };

  //apis - get dialogues
  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const response = await axios.post(GET_SPEECH_FOR_PROJECT_BY_PAGE, {
          userId: userInfo.id,
          projectId: projectFile.id,
          action: "first",
          Current: null,
          pageName: "transcribe",
        });

        const dialogues = await response.data.projectsArray;

        dialogues.forEach((dia, index) => {
          dia.add = false;
          dia.id = index;
        });

        if (dialogues.length <= 0) {
          toast.error("The transcription service didn't finish yet");
        }

        setAudioData(dialogues);
        setLoader(false);
      } catch (err) {
        console.log(err);
        setLoader(false);
      }
    };
    fetchData();
  }, [projectFile.id, userInfo.id]);

  //apis - get characters
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(GET_CHARACTERS_FOR_PROJECT, {
          projectId: projectFile.id,
        });

        setCharacters(response.data.characters);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [projectFile.id]);

  //apis - check final submission status
  useEffect(() => {
    const checkChoppingStatus = async () => {
      try {
        const response = await axios.post(AUDIO_CHOPPING_STATUS_CHECK, {
          projectId: projectFile.id,
        });

        setCheckChoppingStatus(response.data.status);
      } catch (err) {
        console.log(err);
      }
    };
    const checkAudioTrainingStatus = async () => {
      try {
        const response = await axios.post(VOICE_TRAINING_STATUS_CHECK, {
          projectId: projectFile.id,
        });

        setCheckChoppingStatus(response.data.status);
      } catch (err) {
        console.log(err);
      }
    };

    checkChoppingStatus();
    checkAudioTrainingStatus();
  }, [projectFile.id]);

  const handleTimeSync = async (time) => {
    try {
      setLoader(true);
      const positionResponse = await axios.post(GET_SPEECH_POSITION_AROUND_TIME, {
        projectId: projectFile.id,
        time: time
      });

      const number = Math.ceil(positionResponse.data.firstPosition / 10);

      const response = await axios.post(GET_SPEECH_FOR_PROJECT_BY_PAGE, {
        userId: userInfo.id,
        projectId: projectFile.id,
        action: "goto",
        pageNumber: number,
        pageName: "transcribe",
      });

      if (response.data) {
        const sortedArray = await response.data.projectsArray;
        sortedArray.forEach((dia, index) => {
          dia.add = false;
          dia.id = index;
        });

        setAudioData(sortedArray);
        setPageNumber(number);
        setGoToPageNumber("");
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
      toast.error("Error syncing to current time position");
    }
  };

  return (
    <Grid
      item
      container
      // justifyContent={{ xl: "center", lg: "center", md: "center" }}
      justifyContent="center"
      marginTop={2}
    >
      <Loader Load={audioLoader} />
      <Loader Load={loader} />
      <ToastContainer position="top-center" theme="dark" />
      <Grid item container xs={11.5} direction="column">
        <Grid item className={classes["header"]}>
          Transcriber
        </Grid>
        <Grid item container direction="row">
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            lg={5}
            xl={5.85}
            // xs={5.85}
            direction="column"
            marginRight={2}
          >
            <Grid
              item
              container
              className={
                openCharactersFolder
                  ? classes["OnAudioFiles"]
                  : classes["OffAudioFiles"]
              }
              direction="column"
            >
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                className={classes["AudioTitles"]}
                justifyContent="space-between"
              >
                <Grid item container marginLeft={1.5} xs={3} direction="row">
                  <Grid
                    item
                    marginRight={1}
                    className={classes["AudioTitleFont"]}
                  >
                    <AutoStoriesIcon fontSize="medium" />
                  </Grid>
                  <Grid item className={classes["AudioTitleFont"]}>
                    Characters
                  </Grid>
                </Grid>

                <Grid item container marginLeft={1.5} xs={4} direction="row">
                  <Grid item xs={1} marginRight={23}>
                    <textarea
                      className={classes["AudioWorkSpeakerEditInput"]}
                      value={charName.displayName}
                      type="text"
                      onChange={(e) =>
                        setCharName({
                          ...charName,
                          displayName: formatInputs(e.target.value),
                        })
                      }
                      placeholder="Enter Character Name"
                    ></textarea>
                  </Grid>
                  <Grid xs={1} item>
                    <TaskButton
                      onClick={AddNewCharacter}
                      height="24px"
                      style={{ padding: 3 }}
                    >
                      Add
                    </TaskButton>
                  </Grid>
                </Grid>

                <Grid
                  item
                  fontSize="40px"
                  onClick={() => {
                    setOpenCharactersFolder(!openCharactersFolder);
                  }}
                  sx={{ cursor: "pointer" }}
                  className={classes["AudioTitleFont"]}
                  marginRight={2}
                >
                  {openCharactersFolder ? (
                    <KeyboardArrowUpIcon fontSize="large" />
                  ) : (
                    <KeyboardArrowDownIcon fontSize="large" />
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                item
                className={classes["AudioFilesBoxScroller"]}
                xs={8}
              >
                {openCharactersFolder ? (
                  <Grid
                    item
                    container
                    direction="row"
                    gap={3}
                    marginTop={1}
                    marginLeft={1}
                  >
                    {characters?.map((char, index) => (
                      <Grid
                        key={index}
                        container
                        item
                        // xs={2.1}
                        xs={3.5}
                        sm={2.5}
                        md={2}
                        lg={3.5}
                        xl={2.01}
                        alignItems="center"
                        justifyContent="space-between"
                        height="40px"
                        sx={{ fontSize: "10px" }}
                        bgcolor={secondaryColor}
                      >
                        <Grid
                          item
                          xs={2}
                          color={primaryColor}
                          paddingLeft={0.4}
                        >
                          <ContactsIcon />
                        </Grid>
                        <Grid item xs={7} color="#FFFFFF">
                          {/* {char.displayName ? char.displayName : char.speaker} */}
                          <textarea
                            className={classes["CharacterWorkSpeakerEditInput"]}
                            value={
                              char.displayName?.length >= 0
                                ? char.displayName
                                : char.speaker
                            }
                            type="text"
                            onChange={(e) =>
                              editCharacter(formatInputs(e.target.value), index)
                            }
                          ></textarea>
                        </Grid>

                        <Grid
                          item
                          xs={2}
                          onClick={() => removeCharacter(char.speaker)}
                          sx={{ cursor: "pointer", color: "red" }}
                        >
                          <DeleteIcon />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              container
              className={classes["AudioWorkPlace"]}
              marginTop={1}
              direction="column"
              gap={3}
            >
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes["AudioTitles"]}
              >
                <Grid
                  item
                  marginLeft={4.5}
                  className={classes["AudioTitleFont"]}
                >
                  Dialogue
                </Grid>
                <Grid item marginRight={4.5}>
                  <TaskButton
                    className={classes["AudioWorkPlaceButton"]}
                    onClick={() => setWorkPlaceType(!workPlaceType)}
                  >
                    Sound effect / dialogue
                  </TaskButton>
                </Grid>
                {/* <Grid item marginRight={4.5}>
                  <button
                    className={classes["AudioWorkPlaceButton"]}
                    onClick={() =>
                      videoSource === projectFile.videoUrl
                        ? setVidoSource(projectFile.dialogueAudioUrl)
                        : setVidoSource(projectFile.videoUrl)
                    }
                  >
                    Sound Effects on/off
                  </button>
                </Grid> */}
              </Grid>
              <Grid item container>
                <Grid
                  item
                  container
                  direction="row"
                  marginBottom={2}
                  gap={3}
                  className={classes["AudioWorkPlaceChatBoxScroller"]}
                  maxHeight={{
                    xl: "800px",
                    lg: "800px",
                    md: "800px",
                    xs: "360px",
                  }}
                  ref={containerRef}
                >
                  {audioData && workPlaceType ? (
                    <>
                      {audioData.map((data, index) => (
                        <React.Fragment key={index}>
                          <Grid item container direction="row">
                            <Grid
                              item
                              container
                              direction="column"
                              xs={1.8}
                              marginLeft={1}
                              justifyContent="center"
                              alignContent="center"
                              className={classes["AudioWorkPlaceTimeline"]}
                            >
                              <Grid item marginBottom={1.5}>
                                <textarea
                                  className={
                                    classes["AudioWorkPlaceTimelineEdit"]
                                  }
                                  value={formatTime(data.start)}
                                  type="text"
                                  onChange={(e) =>
                                    handleStartTimeChange(e, data.id)
                                  }
                                ></textarea>
                              </Grid>
                              <Grid item>
                                <textarea
                                  className={
                                    classes["AudioWorkPlaceTimelineEdit"]
                                  }
                                  value={formatTime(data.end)}
                                  type="text"
                                  onChange={(e) =>
                                    handleEndTimeChange(e, data.id)
                                  }
                                ></textarea>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              container
                              direction="row"
                              alignContent="center"
                              alignItems="center"
                              // bgcolor="red"
                              // gap={1}
                              // paddingLeft={1.5}
                              justifyContent="space-between"
                              paddingTop={0}
                              xs={9.4}
                              sx={{ cursor: "pointer" }}
                            >
                              <Grid
                                item
                                paddingLeft={1.5}
                                xs={6.3}
                                container
                                className={classes["AudioWorkPlaceBoxes"]}
                                alignContent="center"
                                direction="row"
                                onClick={() => {
                                  setSeekTime(data.start);
                                  setSeekTimeIndex(seekTimeIndex === 1 ? 2 : 1);
                                }}
                              // bgcolor="red"
                              // width="px"
                              >
                                <Grid item xs={12}>
                                  {data.speaker ? (
                                    <>
                                      <Grid
                                        width="200px"
                                        height="22px"
                                        marginBottom={0.5}
                                      >
                                        <Select
                                          value={data.speaker}
                                          onChange={(e) => handleSpeakerChange(e, data.id)}
                                          input={<BootstrapInputForAvalialbe />}
                                          onClick={(e) => e.stopPropagation()} // Prevents event bubbling
                                          onMouseDown={(e) => e.stopPropagation()} // Ensures menu interactions don't bubble
                                        >
                                          {characters?.map((char, index) => (
                                            <MenuItem
                                              key={index}
                                              value={char.speaker}
                                            >
                                              {findDisplayName(char.speaker)}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </Grid>

                                    </>
                                  ) : (
                                    <Grid
                                      width="100px"
                                      height="22px"
                                      marginBottom={1}
                                    >
                                      <Select
                                        value={data.speaker}
                                        onChange={(e) =>
                                          handleSpeakerChange(e, data.id)
                                        }
                                        input={<BootstrapInput />}
                                      >
                                        {characters?.map((char) => (
                                          <MenuItem
                                            key={char.speaker}
                                            value={char.speaker}
                                          >
                                            {findDisplayName(char.speaker)}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </Grid>
                                  )}
                                </Grid>
                                <Grid item xs={12}>
                                  <textarea
                                    className={
                                      classes["AudioWorkTextEditInput"]
                                    }
                                    style={{
                                      color:
                                        currentTime >= data.start &&
                                          currentTime < data.end
                                          ? "red"
                                          : "",
                                    }}
                                    value={data.text}
                                    type="text"
                                    onChange={(e) =>
                                      handleTextChange(e, data.id)
                                    }
                                  ></textarea>
                                </Grid>
                              </Grid>

                              <Grid item xs={1}>
                                <FormControlLabel
                                  control={
                                    <Checkbox checked={data.faceCheck} />
                                  }
                                  label="Face"
                                  labelPlacement="top"
                                  onClick={() => handleFaceCheck(data.id)}
                                />
                              </Grid>

                              <Grid item xs={2} marginTop={1.5}>
                                <Select
                                  value={data.originalAudioEmotion}
                                  onChange={(e) =>
                                    handleDialogueEmotion(e, data.id)
                                  }
                                >
                                  <MenuItem value="neutral">Neutral</MenuItem>
                                  <MenuItem value="happy">Happy</MenuItem>
                                  <MenuItem value="sad">Sad</MenuItem>
                                  <MenuItem value="angry">Angry</MenuItem>
                                  <MenuItem value="afraid">Afraid</MenuItem>
                                </Select>
                              </Grid>
                              <Grid item xs={1.5} marginTop={1.5}>
                                <Select
                                  value={data.originalAudioRating}
                                  onChange={(e) =>
                                    handleDialogueRating(e, data.id)
                                  }
                                // input={<RatingStyle />}
                                >
                                  <MenuItem value={1}>1 𖤐 </MenuItem>
                                  <MenuItem value={2}>2 𖤐 </MenuItem>
                                  <MenuItem value={3}>3 𖤐 </MenuItem>
                                  <MenuItem value={4}>4 𖤐 </MenuItem>
                                  <MenuItem value={5}>5 𖤐 </MenuItem>
                                </Select>
                              </Grid>
                              <Grid item xs={0.8} justifyContent="center">
                                <Grid
                                  item
                                  sx={{
                                    cursor: "pointer",
                                    color: `${secondaryColor}`,
                                    "&:hover": {
                                      color: `${primaryColor}`,
                                    },
                                  }}
                                >
                                  <AddCircleIcon
                                    onClick={() => {
                                      const updatedData = [...audioData];
                                      updatedData[data.id] = {
                                        ...updatedData[data.id],
                                        add: true,
                                      };

                                      setAudioData(updatedData);
                                    }}
                                    fontSize="large"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  fontSize="40px"
                                  sx={{ cursor: "pointer", color: "red" }}
                                >
                                  <DeleteIcon
                                    onClick={() => removeDialogue(data.dbid)}
                                    fontSize="large"
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={0.1} marginTop={2.5}>
                                <Grid
                                  item
                                  sx={{ cursor: "pointer", color: "#353234" }}
                                >
                                  <img
                                    src={joinBelow}
                                    alt=""
                                    width="40px"
                                    height="40px"
                                    onClick={() => {
                                      MergeDialogues(data.id);
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {data.add ? (
                            <Grid
                              item
                              container
                              direction="row"
                              marginLeft={3.1}
                              gap={5}
                            >
                              <Grid
                                item
                                container
                                direction="column"
                                xs={1}
                                // justifyContent="center"
                                marginTop={1.1}
                                className={classes["AudioWorkPlaceTimeline"]}
                              >
                                <Grid color="blue" item marginBottom={1}>
                                  <textarea
                                    className={
                                      classes["AudioWorkPlaceTimelineEditInput"]
                                    }
                                    value={startTimeText}
                                    type="text"
                                    onChange={(e) =>
                                      setStartTimeText(e.target.value)
                                    }
                                  ></textarea>
                                </Grid>
                                <Grid item color="blue">
                                  <textarea
                                    className={
                                      classes["AudioWorkPlaceTimelineEditInput"]
                                    }
                                    value={endTimeText}
                                    type="text"
                                    onChange={(e) =>
                                      setEndTimeText(e.target.value)
                                    }
                                  ></textarea>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                container
                                className={classes["AudioWorkPlaceBoxes"]}
                                direction="column"
                                gap={1}
                                paddingLeft={2}
                                paddingTop={1}
                                xs={5}
                                marginRight={1}
                              >
                                <Grid item container alignContent="center">
                                  {speakerText ? (
                                    <>
                                      {/* <Grid item marginRight={2} xs={1}>
                               
                                      </Grid> */}

                                      <Grid
                                        width="200px"
                                        height="22px"
                                        marginBottom={0.5}
                                      >
                                        <Select
                                          value={speakerText}
                                          onChange={(e) =>
                                            setSpeakerText(e.target.value)
                                          }
                                          input={<BootstrapInputForAvalialbe />}
                                        >
                                          {characters?.map((char, index) => (
                                            <MenuItem
                                              key={index}
                                              value={char.speaker}
                                            >
                                              {findDisplayName(char.speaker)}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      <Grid
                                        width="100px"
                                        height="22px"
                                        marginBottom={0}
                                        item
                                        marginRight={2}
                                        xs={1}
                                      >
                                        <Select
                                          value={speakerText}
                                          onChange={(e) =>
                                            setSpeakerText(e.target.value)
                                          }
                                          input={<BootstrapInput />}
                                        >
                                          {characters?.map((char) => (
                                            <MenuItem
                                              key={char.speaker}
                                              value={char.speaker}
                                            >
                                              {findDisplayName(char.speaker)}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                                <Grid item>
                                  <textarea
                                    style={{ height: "30px" }}
                                    className={
                                      classes["AudioWorkTextEditInput"]
                                    }
                                    value={diaText}
                                    placeholder="write here"
                                    type="text"
                                    onChange={(e) => setDiaText(e.target.value)}
                                  ></textarea>
                                </Grid>
                                <Grid marginTop={1.5}>
                                  <Select
                                    value={audioEmotion}
                                    onChange={(e) =>
                                      setAudioEmotion(e.target.value)
                                    }
                                  >
                                    <MenuItem value="neutral">Neutral</MenuItem>
                                    <MenuItem value="happy">Happy</MenuItem>
                                    <MenuItem value="sad">Sad</MenuItem>
                                    <MenuItem value="angry">Angry</MenuItem>
                                    <MenuItem value="afraid">Afraid</MenuItem>
                                  </Select>
                                </Grid>
                                <Grid marginTop={1.5}>
                                  <Select
                                    value={audioRating}
                                    onChange={(e) =>
                                      setAudioRating(e.target.value)
                                    }
                                  >
                                    <MenuItem value="1">1 𖤐 </MenuItem>
                                    <MenuItem value="2">2 𖤐 </MenuItem>
                                    <MenuItem value="3">3 𖤐 </MenuItem>
                                    <MenuItem value="4">4 𖤐 </MenuItem>
                                    <MenuItem value="5">5 𖤐 </MenuItem>
                                  </Select>
                                </Grid>
                                <Grid item alignContent="center">
                                  <Grid
                                    item
                                    onClick={() => {
                                      AddNewDialogue(data.id);
                                    }}
                                    sx={{
                                      cursor: "pointer",
                                      color: `${secondaryColor}`,
                                      "&:hover": {
                                        color: `${primaryColor}`,
                                      },
                                    }}
                                  >
                                    <SaveIcon fontSize="large" />
                                  </Grid>
                                  <Grid
                                    item
                                    fontSize="40px"
                                    sx={{
                                      cursor: "pointer",
                                      color: "red",
                                    }}
                                  >
                                    <CancelIcon
                                      onClick={() => {
                                        const updatedData = [...audioData];
                                        updatedData[data.id] = {
                                          ...updatedData[data.id],
                                          add: false,
                                        };
                                        setAudioData(updatedData);
                                      }}
                                      fontSize="large"
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    <>
                      <SoundEffectsParts
                        setSeekTime={setSeekTime}
                        seekTimeIndex={seekTimeIndex}
                        setSeekTimeIndex={setSeekTimeIndex}
                        currentTime={currentTime}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              marginTop={1}
            >
              {workPlaceType ? (
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                >
                  <Grid item>
                    <TaskButton
                      onClick={() => nextPrevPageAPI("previous")}
                      disabled={Number(pageNumber) === 1 || pageButtonActivity}
                      width="80px"
                    >
                      Previous
                    </TaskButton>
                  </Grid>

                  <Grid item>
                    <span>
                      Page {pageNumber} of {totalPages}
                    </span>
                  </Grid>

                  <Grid item>
                    <TaskButton
                      width="80px"
                      onClick={() => nextPrevPageAPI("next")}
                      disabled={
                        Number(pageNumber) === totalPages || pageButtonActivity
                      }
                    >
                      Next
                    </TaskButton>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
              <Grid item></Grid>
            </Grid>
            <Grid item container alignItems="center" justifyContent="center">
              {workPlaceType ? (
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                  gap={2}
                  marginTop={1}
                  marginLeft={13.7}
                >
                  <Grid item marginRight={1}>
                    <input
                      type="text"
                      style={{ width: "40px" }}
                      value={goToPageNumber}
                      // placeholder="Enter the page number "
                      onChange={(e) => {
                        setGoToPageNumber(
                          e.target.value >= 1 ? e.target.value : 1
                        );
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TaskButton
                      width="80px"
                      // className={classes["AudioWorkPlaceButton"]}
                      onClick={() => goToPageAPI(goToPageNumber)}
                    >
                      Go
                    </TaskButton>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>

          {/* <VideoSlider
            source={videoSource}
            audioSource={audioSource}
            seekTime={seekTime}
            displayAudioTime={displayAudioTime}
            seekTimeIndex={seekTimeIndex}
            setPausePlay={setPausePlay}
          /> */}

          <UnifiedVideoPlayer
            source={videoSource}
            audioSource={audioSource}
            seekTime={seekTime}
            displayAudioTime={displayAudioTime}
            seekTimeIndex={seekTimeIndex}
            setPausePlay={setPausePlay}
            layout="vertical"
            dualAudio={true}
            showDebug={true}
            customClasses={{
              videoContainer: classes["AudioOutPutVideo"],
              controlsContainer: classes["AudioOutPutController"],
              timeDisplay: classes["AudioOutPutShownTime"],
              selector: classes["AudioOutPutShowTimeOptionBox"],
              timeDisplay: classes["AudioWorkPlaceTimelineEditTranslation"],
            }}
            onTimeSync={handleTimeSync}
          />

          <Grid
            item
            container
            xs={12}
            direction="row"
            alignContent="center"
            justifyContent="flex-end"
            marginTop={2}
          ></Grid>
          <Grid
            item
            container
            xs={11.8}
            direction="row"
            alignContent="center"
            justifyContent="flex-end"
            marginTop={2}
            gap={5}
          >
            <Grid item>
              <TaskButton
                onClick={() => finalSubmission()}
                disabled={loading || isConfirmationOpen}
                style={{ background: (loading || isConfirmationOpen) ? "grey" : "" }}
                className={classes["AudioWorkPlaceButton"]}
              >
                Submit All
              </TaskButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TranscriberScreen;
