import { Button, Grid } from "@mui/material";
import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import classes from "../../Assets/Styles/LipQAScreen.module.css";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Loader from "../../Components/Loader";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Store } from "../../Store";
import {
  formatInputs,
  formatTime,
  getLanguageName,
  processPathUrl,
} from "../../utils/generalFunctions";
import QAVideoSlider from "../../Components/AudioTools/QAVideoSlider";
import { getDownloadUrlForFile } from "../../config/firebase";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import { GET_LIP, MERGING_LIPS_API, UPDATE_ONE_LIP } from "../../MProjectConst";
import TaskButton from "../../Components/Buttons/TaskButton";
import { GET_SPEECH_POSITION_AROUND_TIME } from "../../MProjectConst";
import UnifiedVideoPlayer from "../../Components/AudioTools/UnifiedVideoPlayer";


const LipQAScreen = () => {
  const params = useParams();
  const { language: languageCode } = params;
  const { state } = useContext(Store);
  const { projectFile } = state;
  const [audioData, setAudioData] = useState([]);
  const [faceData, setFaceData] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [seekTime] = useState(0);
  const [seekTimeIndex] = useState(0);
  const [audioLoader, setAudioLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [videoSource, setVidoSource] = useState();
  const [sceneModels, setSceneModels] = useState([]);
  const [pausePlay, setPausePlay] = useState(false);

  const [versionName, setVersionName] = useState("");

  const containerRef = useRef(null);

  // pagination items
  const itemsPerPage = 1;
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = Math.ceil(audioData?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = audioData?.slice(startIndex, endIndex);

  const handleTimeSync = async (time) => {
    try {
      setLoader(true);
      const positionResponse = await axios.post(GET_SPEECH_POSITION_AROUND_TIME, {
        projectId: projectFile.id,
        time: time
      });

      const number = Math.ceil(positionResponse.data.firstPosition / 10);
      setCurrentPage(number);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
      toast.error("Error syncing to current time position");
    }
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };
  //-------------------------------------

  const displayAudioTime = useCallback((data) => {
    setCurrentTime(data);
  }, []);
  //api for handling model selection changes
  const handleModelSelection = async (e, index) => {
    const updatedData = [...audioData];

    updatedData[index].tempSelectedScene = e.target.value;

    setAudioData(updatedData);
    setVidoSource(e.target.value);
  };

  const handleSceneChanger = (index, direction) => {
    const updatedData = [...audioData];
    let sceneUrl = updatedData[index].tempSelectedScene;
    let newScene;

    const changedToSceneInex = sceneModels.findIndex(
      (scene) => scene.url === sceneUrl
    );

    if (direction === "next") {
      newScene = sceneModels[changedToSceneInex + 1];
    } else if (direction === "prev") {
      newScene = sceneModels[changedToSceneInex - 1];
    }

    if (changedToSceneInex === -1) {
      newScene = sceneModels[0];
    }

    try {
      updatedData[index].tempSelectedScene = newScene.url;

      setVidoSource(newScene.url);
      setAudioData(updatedData);
    } catch (error) {
      // console.log("out of index");
    }
  };

  const handleSceneReview = async (index, review, url) => {
    let model;
    const updatedData = [...audioData];
    let updatedFaceData = [...faceData];

    const checkIfItExists = updatedFaceData.find(
      (obj) => obj.uid === updatedData[index].uid
    );

    model = sceneModels?.find((obj) => obj?.url === url);

    const sceneModel = {
      url: url,
      model: model.modelName,
      review: review,
    };

    if (checkIfItExists && review === "accepted") {
      if (
        review === "accepted" &&
        checkIfItExists.sceneModel.model === model?.modelName &&
        checkIfItExists.sceneModel.review === "accepted"
      ) {
        toast.error("This scene is already accepted ");
        return;
      } else {
        updatedFaceData = updatedFaceData.filter(
          (item) => !(item.uid === updatedData[index].uid)
        );
        const updatedFaceObject = {
          speaker: updatedData[index].speaker,
          start: updatedData[index].start,
          end: updatedData[index].end,
          sceneModel: sceneModel,
          combined_audio_path: updatedData[index].combined_audio_path,
          review: review,
          uid: updatedData[index].uid,
        };
        updatedFaceData.push(updatedFaceObject);
      }
    }

    if (!checkIfItExists && review === "accepted") {
      const updatedFaceObject = {
        speaker: updatedData[index].speaker,
        start: updatedData[index].start,
        end: updatedData[index].end,
        sceneModel: sceneModel,
        combined_audio_path: updatedData[index].combined_audio_path,
        review: review,
        uid: updatedData[index].uid,
      };

      updatedFaceData.push(updatedFaceObject);
    }

    if (checkIfItExists && review === "rejected") {
      if (
        review === "rejected" &&
        checkIfItExists.sceneModel.model === model?.modelName &&
        checkIfItExists.sceneModel.review === "rejected"
      ) {
        toast.error("This scene is already rejected ");
        return;
      } else {
        updatedFaceData = updatedFaceData.filter(
          (item) => !(item.uid === updatedData[index].uid)
        );
        const updatedFaceObject = {
          speaker: updatedData[index].speaker,
          start: updatedData[index].start,
          end: updatedData[index].end,
          sceneModel: sceneModel,
          combined_audio_path: updatedData[index].combined_audio_path,
          review: review,
          uid: updatedData[index].uid,
        };
        updatedFaceData.push(updatedFaceObject);
      }
    }

    if (!checkIfItExists && review === "rejected") {
      const updatedFaceObject = {
        speaker: updatedData[index].speaker,
        start: updatedData[index].start,
        end: updatedData[index].end,
        sceneModel: sceneModel,
        combined_audio_path: updatedData[index].combined_audio_path,
        review: review,
        uid: updatedData[index].uid,
      };

      updatedFaceData.push(updatedFaceObject);
    }

    try {
      await axios.post(UPDATE_ONE_LIP, {
        projectId: projectFile.id,
        languageCode: languageCode,
        faceData: updatedFaceData,
      });
      setFaceData(updatedFaceData);
      toast.success("Model has been rated");
      setAudioLoader(false);
    } catch (error) {
      console.log(error);
      toast.error("Error in rating the Model ");

      setAudioLoader(false);
    }
  };

  const displayTheChosenSceneReview = (index, url) => {
    const updatedData = [...audioData];
    let updatedFaceData = [...faceData];
    const checkIfItExists = updatedFaceData.find(
      (obj) => obj.uid === updatedData[index].uid
    );

    if (checkIfItExists) {
      return (
        checkIfItExists.sceneModel.review +
        " - " +
        getVideoByModelName(checkIfItExists.sceneModel.model)
      );
    } else {
      return "No review";
    }
  };

  function getVideoByModelName(modelName) {
    const video1 = "video 1";
    const video2 = "video 2";

    if (modelName === "lip_160") {
      return video1;
    } else if (modelName === "lip_pp") {
      return video2;
    } else {
      return null;
    }
  }

  const finalSubmission = async () => {
    if (!versionName) {
      toast.error("Enter an version name");

      return;
    }

    toast.success("New version is being processed");
    try {
      await axios.post(MERGING_LIPS_API, {
        projectId: projectFile.id,
        langCode: languageCode,
        version: versionName,
      });

      setVersionName("");
    } catch (err) {
      console.log(err);
    }
  };

  //scenes apis
  useEffect(() => {
    const fetchData = async () => {
      let currentSelectedScene;
      setLoader(true);
      try {
        const response = await axios.post(GET_LIP, {
          projectId: projectFile.id,
          languageCode: languageCode,
        });

        let data;

        const url = await processPathUrl(response.data.path);
        const faces = response.data.faces;

        if (url) {
          try {
            const response = await axios.get(url);
            data = response.data;
          } catch (error) {
            toast.error("Face boxes are not found!!");
            console.log("Error in fetching Face boxes", error);
          }
        }

        let sortedArray = await data.sort((a, b) => {
          if (a.start < b.start) return -1;
          if (a.start > b.start) return 1;
          if (a.end < b.end) return -1;
          if (a.end > b.end) return 1;

          return 0;
        });

        sortedArray.forEach((dia, index) => {
          dia.add = false;
          dia.id = index;
          dia.tempSelectedScene = currentSelectedScene;
        });

        setFaceData(faces);
        setAudioData(sortedArray);
        setCurrentPage(1);
        setLoader(false);
      } catch (err) {
        console.log(err);
        setLoader(false);
      }
    };
    fetchData();
  }, [projectFile.id]);

  //model apis
  useEffect(() => {
    const getFaceModels = async () => {
      const updatedData = [...audioData];

      try {
        const speakerName = currentItems[0].speaker;
        const startTime = currentItems[0].start * 1000;
        const endTime = currentItems[0].end * 1000;
        const projectName = projectFile.name;
        const currentLanguage = languageCode;
        const modelName1 = "lip_160";
        const modelName2 = "lip_pp";
        // const modelName2 = "lip_170";

        const finalPath1 = `/${projectName}/synced_faces/${currentLanguage}/${speakerName}/${startTime}_${endTime}_${speakerName}/${modelName1}_${startTime}_${endTime}_${speakerName}.mp4`;
        const finalPath3 = `/${projectName}/synced_faces/${currentLanguage}/${speakerName}/${startTime}_${endTime}_${speakerName}/${modelName2}_${startTime}_${endTime}_${speakerName}.mp4`;
        // const finalPath2 = `/${projectName}/synced_faces/${currentLanguage}/${speakerName}/${startTime}_${endTime}_${speakerName}/${modelName2}_b${startTime}_${endTime}_${speakerName}.mp4`;

        let url1;
        let url2;

        try {
          url1 = await getDownloadUrlForFile(finalPath1);
        } catch {
          url1 = "empty";
        }

        try {
          url2 = await getDownloadUrlForFile(finalPath3);
        } catch {
          url2 = "empty";
        }

        const SceneModels = [
          { rating: null, url: url1, model: "Video 1", modelName: modelName1 },
          { rating: null, url: url2, model: "Video 2", modelName: modelName2 },
        ];

        // console.log(url2);

        updatedData[currentPage - 1].tempSelectedScene = url1;
        setAudioData(updatedData);

        setSceneModels(SceneModels);
        setVidoSource(SceneModels[0].url);
      } catch (err) {
        if (audioData) {
          toast.error("No Models for this Scene", { autoClose: 1000 });
        }
        if (currentPage > 0 && currentPage <= updatedData.length) {
          updatedData[currentPage - 1].tempSelectedScene = "";
        }
        setAudioData(updatedData);
      }
    };
    if (currentPage !== 0) {
      getFaceModels();
    }
  }, [currentPage]);

  return (
    <Grid
      item
      container
      justifyContent="center"
      marginTop={2}
      style={{ fontFamily: "var(--global-text-font)" }}
    >
      {/* {console.log(faceData)} */}
      <Loader Load={audioLoader} />
      <Loader Load={loader} />
      <ToastContainer position="top-center" theme="dark" />
      <Grid item container xs={11.5} direction="column">
        <Grid item className={classes["header"]}>
          Lips Quality Assurance
        </Grid>
        <Grid item container direction="row">
          <Grid item container xs={5.85} direction="column" marginRight={2}>
            {/* <Grid
              item
              container
              className={
                true ? classes["OnAudioFiles"] : classes["OffAudioFiles"]
              }
              direction="column"
            >
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                className={classes["AudioTitles"]}
                justifyContent="space-between"
              >
                <Grid item container marginLeft={1.5} xs={6} direction="row">
                  <Grid item marginRight={1}>
                    <AutoStoriesIcon fontSize="medium" />
                  </Grid>
                  <Grid item className={classes["AudioTitleFont"]}>
                    Language : {" " + getLanguageName(languageCode)}
                  </Grid>
                </Grid>

                <Grid
                  item
                  fontSize="40px"
                  onClick={() => {
                    // setOpenCharactersFolder(!openCharactersFolder);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  {true ? (
                    <KeyboardArrowUpIcon fontSize="large" />
                  ) : (
                    <KeyboardArrowDownIcon fontSize="large" />
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                item
                className={classes["AudioFilesBoxScroller"]}
                xs={8}
              >
                <Grid
                  item
                  container
                  direction="row"
                  gap={3}
                  marginTop={1}
                  marginLeft={1}
                >
                  .
                </Grid>
              </Grid>
            </Grid> */}
            <Grid
              item
              container
              className={classes["AudioWorkPlace"]}
              // marginTop={1}
              direction="column"
            >
              <Grid
                item
                container
                direction="row"
                // justifyContent="space-between"
                alignItems="center"
                className={classes["AudioTitles"]}
              >
                <Grid
                  item
                  marginRight={38}
                  marginLeft={4.5}
                  className={classes["AudioTitleFont"]}
                >
                  Scene Number : {" " + currentPage}
                </Grid>
              </Grid>
              <Grid item container>
                <Grid
                  item
                  container
                  direction="row"
                  ref={containerRef}
                  alignItems="center"
                  justifyContent="center"
                >
                  {audioData ? (
                    <>
                      {currentItems.map((data, index) => (
                        <Grid item container direction="row" key={index}>
                          <Grid
                            item
                            container
                            direction="column"
                            marginTop={3}
                            gap={10}
                            justifyItems="center"
                            alignItems="center"
                          >
                            {/* {console.log(data.start)} */}
                            <Grid item>
                              Start Time : {formatTime(data.start)}
                            </Grid>
                            <Grid item>End Time : {formatTime(data.end)}</Grid>
                            <Grid item>Speaker Name : {data.speaker}</Grid>
                            <Grid item> Choose a video to view </Grid>
                            <Grid
                              item
                              container
                              direction="row"
                              justifyContent="center"
                              // alignContent="center"
                              alignItems="center"
                              gap={4}
                            >
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    handleSceneChanger(data.id, "prev")
                                  }
                                >
                                  <SkipPreviousIcon />
                                </Button>
                              </Grid>

                              <Grid item>
                                <select
                                  className={classes["AudioWorkSelector"]}
                                  value={data?.tempSelectedScene}
                                  onChange={(e) => {
                                    handleModelSelection(e, data.id);
                                  }}
                                // disabled={true}
                                >
                                  <option value="">select model</option>
                                  {sceneModels?.map((audio, index) => (
                                    <option value={audio.url} key={index}>
                                      {audio.model}
                                    </option>
                                  ))}
                                </select>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    handleSceneChanger(data.id, "next")
                                  }
                                >
                                  <SkipNextIcon />
                                </Button>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              container
                              direction="row"
                              justifyContent="center"
                              alignContent="center"
                              gap={3}
                            >
                              <Grid item> Review</Grid>
                              <Grid item>
                                <TaskButton
                                  width="80px"
                                  onClick={() =>
                                    handleSceneReview(
                                      data.id,
                                      "accepted",
                                      data?.tempSelectedScene
                                    )
                                  }
                                >
                                  Accept
                                </TaskButton>
                              </Grid>
                              <Grid item>
                                <TaskButton
                                  width="80px"
                                  onClick={() =>
                                    handleSceneReview(
                                      data.id,
                                      "rejected",
                                      data?.tempSelectedScene
                                    )
                                  }
                                >
                                  Reject
                                </TaskButton>
                              </Grid>
                              <Grid
                                item
                                container
                                alignContent="center"
                                justifyContent="center"
                              >
                                {displayTheChosenSceneReview(
                                  data.id,
                                  data?.tempSelectedScene
                                )}
                              </Grid>
                            </Grid>

                            {/* <Grid
                              item
                              container
                              direction="row"
                              justifyContent="center"
                              alignContent="center"
                              gap={4}
                            >
                              <Grid item>Select a acceptable video</Grid>

                              <Grid item>
                                <button
                                  onClick={() =>
                                    handleSelectedModel(
                                      data.tempSelectedScene,
                                      data.id
                                    )
                                  }
                                >
                                  select
                                </button>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              container
                              direction="row"
                              justifyContent="center"
                              alignContent="center"
                              gap={4}
                            >
                              <Grid item>Selected Video: </Grid>
                              <Grid item>{findSelectedScene(data.id)}</Grid>
                            </Grid> */}
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  ) : (
                    <Grid
                      container
                      justifyContent="center"
                      alignContent="center"
                    >
                      <Grid item>No faces scenes</Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              marginTop={1.5}
            >
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                gap={2}
              >
                <Grid item>
                  <TaskButton
                    onClick={() => {
                      goToPage(currentPage - 1);
                      setSceneModels([]);
                    }}
                    width="120px"
                    disabled={currentPage === 1}
                  >
                    Previous Scene
                  </TaskButton>
                </Grid>

                <Grid item>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                </Grid>
                <Grid item>
                  <TaskButton
                    onClick={() => {
                      goToPage(currentPage + 1);
                      setVidoSource("");
                      setSceneModels([]);
                    }}
                    disabled={currentPage === totalPages}
                    width="120px"
                  >
                    Next Scene
                  </TaskButton>
                </Grid>
              </Grid>
              <Grid item container alignItems="center" justifyContent="center">
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                  gap={2}
                  marginTop={1}
                >
                  <Grid item>Go</Grid>

                  <Grid item>
                    <input
                      type="text"
                      style={{ width: "30px" }}
                      value={currentPage}
                      onChange={(e) =>
                        setCurrentPage(
                          Number(
                            e.target.value <= totalPages
                              ? e.target.value >= 1
                                ? e.target.value
                                : 1
                              : totalPages
                          )
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* <QAVideoSlider
            source={videoSource}
            seekTime={seekTime}
            seekTimeIndex={seekTimeIndex}
            displayAudioTime={displayAudioTime}
          /> */}

          <UnifiedVideoPlayer
            source={videoSource}
            seekTime={seekTime}
            displayAudioTime={displayAudioTime}
            seekTimeIndex={seekTimeIndex}
            setPausePlay={setPausePlay}
            layout="vertical"
            dualAudio={false}
            showDebug={false}
            customClasses={{
              videoContainer: classes["AudioOutPutVideo"],
              controlsContainer: classes["AudioOutPutController"],
              timeDisplay: classes["AudioOutPutShownTime"],
              selector: classes["AudioOutPutShowTimeOptionBox"],
            }}
            onTimeSync={handleTimeSync}
          />

          <Grid
            item
            container
            xs={12}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            marginTop={2}
            gap={3}
          >
            <Grid item xs={1}>
              <input
                style={{ width: "150px", height: "20px" }}
                className={classes["AudioWorkNoteEditInput"]}
                type="text"
                placeholder="Enter version name"
                value={versionName}
                onChange={(e) => setVersionName(formatInputs(e.target.value))}
              />
            </Grid>
            <Grid item>
              <TaskButton
                onClick={() => finalSubmission()}
              // disabled={true}
              >
                Submit All
              </TaskButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LipQAScreen;
