import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import classes from "../../Assets/Styles/Translation.module.css";
import {
  formatTime,
  getLanguageName,
  timeStringToSeconds,
} from "../../utils/generalFunctions";
import Loader from "../../Components/Loader";
import axios from "axios";
import { Store } from "../../Store";
import {
  UPDATE_ONE_SPEECH,
  GET_CHARACTERS_FOR_PROJECT,
  // UPLOAD_TRANSLATOR_VOICE,
  GET_SPEECH_FOR_PROJECT_BY_PAGE,
  GET_SPEECH_POSITION_AROUND_TIME
} from "../../MProjectConst";
import { ToastContainer, toast } from "react-toastify";
import { useMediaQuery } from "@mui/material";
import ReactAudioPlayer from "react-audio-player";
import VideoSliderHorizontalTrans from "../../Components/AudioTools/VideoSliderHorizontalTrans";
import UnifiedVideoPlayer from "../../Components/AudioTools/UnifiedVideoPlayer";
import { useParams } from "react-router-dom";
import TranslateIcon from "@mui/icons-material/Translate";
import { primaryColor, secondaryColor } from "../../utils/constants";
import TaskButton from "../../Components/Buttons/TaskButton";

// import ReactRecorder from "../../Components/VoiceRecording/ReactRecorder";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import MicNoneIcon from "@mui/icons-material/MicNone";
// import TuneIcon from "@mui/icons-material/Tune";

const TranslatorScreen = () => {
  const params = useParams();
  const { language: languageCode } = params;
  const screenIsOver1200px = useMediaQuery("(min-width: 1200px)");
  const { state } = useContext(Store);
  const { projectFile, userInfo } = state;
  const [characters, setCharacters] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [seekTime, setSeekTime] = useState(0);
  const [seekTimeIndex, setSeekTimeIndex] = useState(0);
  const [scrollableItem, setScrollableItem] = useState(0);
  const [scrollableItemLastUpdated, setScrollableItemLastUpdated] = useState(0);
  const [audioLoader, setAudioLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [pausePlay, setPausePlay] = useState(false);

  // const [newAudioRecorder, setNewAudioRecorder] = useState(null);

  const numberOfDialogues = projectFile.numberOfDialogues;

  const timeoutIdRef = useRef(null);

  // pagination items
  const [pageButtonActivity, setPageButtonActivity] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [goToPageNumber, setGoToPageNumber] = useState();
  const itemsPerPage = 10;
  const totalPages = Math.ceil(numberOfDialogues / itemsPerPage);

  const containerRef = useRef(null);

  useEffect(() => {
    checkTimeInRange(currentTime);
  }, [currentTime]);

  const scrollToItem = (index) => {
    if (containerRef.current) {
      const item = containerRef.current.children[index];
      if (item) {
        item.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  };

  useEffect(() => {
    if (pausePlay) {
      if (screenIsOver1200px) {
        scrollToItem(scrollableItem - 1);
      }
    }
  }, [scrollableItem, pausePlay, screenIsOver1200px]);

  const displayAudioTime = useCallback((data) => {
    setCurrentTime(data);
  }, []);

  // function findNewestUrlCreated(objects) {
  //   if (objects === undefined) {
  //     return "";
  //   }

  //   if (objects.length === 0) {
  //     return null;
  //   }

  //   // Sort the array of objects based on creation time in descending order
  //   const sortedObjects = objects.sort((a, b) => {
  //     const dateA = new Date(a.creationTime);
  //     const dateB = new Date(b.creationTime);
  //     return dateB - dateA;
  //   });

  //   // Return the object with the newest creation time (first element after sorting)
  //   return sortedObjects[0].url;
  // }

  function findDisplayName(speaker) {
    const result = characters.find((char) => char.speaker === speaker);

    if (result?.displayName) {
      return result.displayName;
    }
    return speaker;
  }

  function checkTimeInRange(currentTime) {
    if (audioData) {
      const itemInTimeRange = audioData.find((item) => {
        return currentTime >= item.start && currentTime < item.end;
      });

      if (itemInTimeRange) {
        if (itemInTimeRange.id !== scrollableItemLastUpdated) {
          setScrollableItemLastUpdated(itemInTimeRange.id);
          setScrollableItem(itemInTimeRange.id);
        }
      }
    }
  }

  const findDefaultEmotion = (speaker, originalAudioEmotion) => {
    const character = projectFile?.characters?.find(
      (char) => char.speaker === speaker
    );
    const characterVoice = character?.voices?.find(
      (voice) => voice.emotion === originalAudioEmotion
    );
    if (!characterVoice) {
      return "";
    }
    return characterVoice.voice_id;
  };

  const checkLanguage = (translations) => {
    const updatedTranslations = [...translations];
    const existingLanguageCodes = translations?.map(
      (transLang) => transLang?.languageCode
    );

    projectFile.finalLang.forEach((langCode) => {
      if (!existingLanguageCodes.includes(langCode)) {
        updatedTranslations.push({
          languageCode: langCode,
          translatedText: "",
          translatedAudioList: [],
          translatedAudio: "",
          translatedAudioRating: "",
          translatedAudioNote: null,
          translatedAudioPath: null,
          translatedRecording: "",
        });
      }
    });

    // Return the updated translations array
    return updatedTranslations;
  };

  const findTransObjectsbyLangCode = (array) => {
    const index = array.find((obj) => obj.languageCode === languageCode);
    return index;
  };

  //+ Start time api for dialogue
  function handleStartTimeChange(e, index) {
    const newTime = timeStringToSeconds(e.target.value);
    if (!newTime) {
      return;
    }

    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      start: newTime,
    };

    setAudioData(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 2300);
  }
  //+ End time api for dialogue
  function handleEndTimeChange(e, index) {
    const newTime = timeStringToSeconds(e.target.value);
    if (!newTime) {
      return;
    }

    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      end: newTime,
    };

    setAudioData(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 2300);
  }

  //api for handling notes change
  function handleNoteChange(e, index) {
    const updatedData = [...audioData];

    updatedData[index].translations = updatedData[index].translations.map(
      (prev) => {
        if (prev.languageCode === languageCode) {
          return {
            ...prev,
            translatedAudioNote: e.target.value,
          };
        } else {
          return prev;
        }
      }
    );
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 1650);

    setAudioData(updatedData);
  }

  //api for handling voice recording
  // function startRecordingVoice(index) {
  //   const updatedData = [...audioData];
  //   updatedData[index] = {
  //     ...updatedData[index],
  //     record: true,
  //     recordedBlob: false,
  //   };

  //   updatedData[index].translations = updatedData[index].translations.map(
  //     (prev) => {
  //       if (prev.languageCode === languageCode) {
  //         return {
  //           ...prev,
  //           translatedRecording: false,
  //         };
  //       } else {
  //         return prev;
  //       }
  //     }
  //   );
  //   setAudioData(updatedData);
  // }

  //api for handling original text
  function handleTextChange(e, index) {
    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      text: e.target.value,
    };

    setAudioData(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 1650);
  }

  //api for handling translation text
  function handleTranslationChange(e, index) {
    const updatedData = [...audioData];
    // updatedData[index].translations = {
    //   ...updatedData[index].translations,
    //   translatedText: e.target.value,
    // };

    updatedData[index].translations = updatedData[index].translations.map(
      (prev) => {
        if (prev.languageCode === languageCode) {
          return {
            ...prev,
            translatedText: e.target.value,
          };
        } else {
          return prev;
        }
      }
    );

    setAudioData(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 1650);
  }

  //api voice audio
  // const handleRecordedAudio = async (data) => {
  //   setAudioLoader(true);
  //   const updatedData = [...audioData];
  //   updatedData[data.id] = {
  //     ...updatedData[data.id],
  //     record: false,
  //     recordedBlob: newAudioRecorder.blobURL,
  //   };

  //   const formData = new FormData();

  //   formData.append(
  //     "file",
  //     newAudioRecorder.blob,
  //     data.speaker + data.start + "_" + data.end
  //   );

  //   formData.append("speaker", data.speaker);
  //   formData.append("start", data.start);
  //   formData.append("end", data.end);

  //   try {
  //     const response = await axios.post(UPLOAD_TRANSLATOR_VOICE, formData);

  //     updatedData[data.id].translations = updatedData[data.id].translations.map(
  //       (prev) => {
  //         if (prev.languageCode === languageCode) {
  //           return {
  //             ...prev,
  //             translatedRecording: response.data.url,
  //           };
  //         } else {
  //           return prev;
  //         }
  //       }
  //     );

  //     try {
  //       const response = await axios.post(UPDATE_ONE_SPEECH, {
  //         projectId: projectFile.id,
  //         dialogue: updatedData[data.id],
  //       });
  //       setNewAudioRecorder(null);
  //       setAudioData(updatedData);

  //       toast.success("Voice recording has been uploaded");

  //       setAudioLoader(false);
  //     } catch (error) {
  //       console.log(error);
  //       setAudioLoader(false);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     setAudioLoader(false);
  //   }
  // };

  //+ face api for dialogue
  const handleKeepOriginalText = async (index) => {
    setAudioLoader(true);
    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      keepOriginalText: !updatedData[index].keepOriginalText,
    };

    setAudioData(updatedData);

    try {
      const response = await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
      if (response.data) {
        setAudioData(updatedData);
        setAudioLoader(false);
      }
    } catch (err) {
      console.log(err);
      setAudioLoader(false);
    }
  };

  //api for handling notes change
  function handleTranslatedSentenceChange(e, index) {
    const updatedData = [...audioData];

    updatedData[index].translations = updatedData[index].translations.map(
      (prev) => {
        if (prev.languageCode === languageCode) {
          return {
            ...prev,
            translatedText: e.target.value,
          };
        } else {
          return prev;
        }
      }
    );
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 1650);

    setAudioData(updatedData);
  }

  const nextPrevPageAPI = async (action) => {
    setPageButtonActivity(true);
    if (pageNumber === 1 && action === "previous") {
      return;
    }
    try {
      const response = await axios.post(GET_SPEECH_FOR_PROJECT_BY_PAGE, {
        userId: userInfo.id,
        projectId: projectFile.id,
        action: action,
        pageName: "translate",
      });

      if (response.data) {
        const dialogues = await response.data.projectsArray;

        dialogues.forEach((dia, index) => {
          dia.id = index;
          dia.tempTransEmotion = findDefaultEmotion(
            dia.speaker,
            dia.originalAudioEmotion
          );
          dia.translatedAudioDurationTemp = null;
          dia.record = false;
          dia.translations = checkLanguage(dia.translations);
          dia.filterShow = true;
        });

        setAudioData(dialogues);
        if (action === "next") {
          setPageNumber(Number(pageNumber) + 1);
        } else if (action === "previous") {
          setPageNumber(Number(pageNumber) - 1);
        }
        setPageButtonActivity(false);
      }
    } catch (err) {
      console.log(err);
      toast.error("Error in getting the next page");
    }
  };

  const goToPageAPI = async (number) => {
    setLoader(true);

    try {
      const response = await axios.post(GET_SPEECH_FOR_PROJECT_BY_PAGE, {
        userId: userInfo.id,
        projectId: projectFile.id,
        action: "goto",
        pageNumber: number,
        pageName: "translate",
      });

      if (response.data) {
        const dialogues = await response.data.projectsArray;

        dialogues.forEach((dia, index) => {
          dia.id = index;
          dia.tempTransEmotion = findDefaultEmotion(
            dia.speaker,
            dia.originalAudioEmotion
          );
          dia.translatedAudioDurationTemp = null;
          dia.record = false;
          dia.translations = checkLanguage(dia.translations);
          dia.filterShow = true;
        });

        setAudioData(dialogues);
        setGoToPageNumber("");
        setPageNumber(number);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
      toast.error("Error in getting the next page");
    }
  };

  //apis - get characters
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(GET_CHARACTERS_FOR_PROJECT, {
          projectId: projectFile.id,
        });

        setCharacters(response.data.characters);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [projectFile.id]);

  //apis - dialogue
  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const response = await axios.post(GET_SPEECH_FOR_PROJECT_BY_PAGE, {
          userId: userInfo.id,
          projectId: projectFile.id,
          action: "first",
          Current: null,
          pageName: "translate",
        });

        const dialogues = await response.data.projectsArray;

        dialogues.forEach((dia, index) => {
          dia.id = index;
          dia.tempTransEmotion = findDefaultEmotion(
            dia.speaker,
            dia.originalAudioEmotion
          );
          dia.translatedAudioDurationTemp = null;
          dia.record = false;
          dia.translations = checkLanguage(dia.translations);
          dia.filterShow = true;
        });

        setAudioData(dialogues);
        setLoader(false);
      } catch (err) {
        console.log(err);
        setLoader(false);
      }
    };
    fetchData();
  }, []);

  const handleTimeSync = async (time) => {
    try {
      setLoader(true);
      const positionResponse = await axios.post(GET_SPEECH_POSITION_AROUND_TIME, {
        projectId: projectFile.id,
        time: time
      });

      const number = Math.ceil(positionResponse.data.firstPosition / 10);

      const response = await axios.post(GET_SPEECH_FOR_PROJECT_BY_PAGE, {
        userId: userInfo.id,
        projectId: projectFile.id,
        action: "goto",
        pageNumber: number,
        pageName: "translate",  // Changed from 'transcribe' to 'translate'
      });

      if (response.data) {
        const dialogues = await response.data.projectsArray;

        dialogues.forEach((dia, index) => {
          dia.id = index;
          dia.tempTransEmotion = findDefaultEmotion(
            dia.speaker,
            dia.originalAudioEmotion
          );
          dia.translatedAudioDurationTemp = null;
          dia.record = false;
          dia.translations = checkLanguage(dia.translations);
          dia.filterShow = true;
        });

        setAudioData(dialogues);
        setGoToPageNumber("");
        setPageNumber(number);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
      toast.error("Error syncing to current time position");
    }
  };

  return (
    <Grid
      item
      container
      justifyContent="center"
      marginTop={4}
      style={{ fontFamily: "var(--global-text-font)" }}
    >
      <ToastContainer position="top-center" theme="dark" />
      <Loader Load={audioLoader} />
      <Loader Load={loader} />

      {/* {console.log(audioData)} */}

      <Grid item container xs={11.3} direction="column">
        <Grid item className={classes["header"]}>
          Translator
        </Grid>
        <Grid item container direction="row">
          {/* <VideoSliderHorizontalTrans
            source={projectFile.videoUrl}
            seekTime={seekTime}
            displayAudioTime={displayAudioTime}
            seekTimeIndex={seekTimeIndex}
            setPausePlay={setPausePlay}
          /> */}

          <UnifiedVideoPlayer
            source={projectFile.videoUrl}
            seekTime={seekTime}
            displayAudioTime={displayAudioTime}
            seekTimeIndex={seekTimeIndex}
            setPausePlay={setPausePlay}
            layout="horizontal"
            dualAudio={false}
            enableScroll={true}
            customClasses={{
              container: classes["AudioOutPutHorizontal"],
              containerStyles: {},
              videoContainer: classes["AudioOutPutVideoHorizontal"],
              controlsContainer: classes["AudioOutPutController"],
              timeDisplay: classes["AudioOutPutShownTime"],
              selector: classes["AudioOutPutShowTimeOptionBox"],
            }}
            onTimeSync={handleTimeSync}
          />

          <Grid item container xs={12}>
            <Grid
              item
              container
              className={classes["AudioWorkPlace"]}
              //   marginTop={1}
              direction="column"
              height="1520px"
            >
              <Grid item container direction="row">
                <Grid item xs={1.1} bgcolor={"#EBEBEB"}>
                  <Grid
                    item
                    container
                    marginTop={1}
                    marginBottom={1}
                    sx={{
                      color: "black",
                      fontFamily: "var(--global-text-font)",
                      fontSize: "13px",
                    }}
                    direction="row"
                    justifyContent="space-evenly"
                  >
                    <Grid item>{getLanguageName(projectFile.orginalLang)}</Grid>
                    <Grid
                      item
                      sx={{
                        color: `${secondaryColor}`,
                      }}
                    >
                      <TranslateIcon fontSize="small" />
                    </Grid>
                    <Grid item> {getLanguageName(languageCode)}</Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={10.76}>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    sx={{
                      bgcolor: `${secondaryColor}`,
                      color: `${primaryColor}`,
                      fontWeight: 600,
                    }}
                    paddingLeft={2}
                    paddingRight={2}
                    justifyContent="space-between"
                  >
                    <Grid item xs={3} container justifyContent="center">
                      <Grid item>Original</Grid>
                    </Grid>

                    <Grid item xs={3} container justifyContent="center">
                      <Grid item>Translation</Grid>
                    </Grid>

                    <Grid item xs={2} container justifyContent="center">
                      <Grid item>Original</Grid>
                    </Grid>
                    <Grid item xs={2} container justifyContent="center">
                      <Grid item>Notes</Grid>
                    </Grid>
                    {/* <Grid item xs={2} container justifyContent="center">
                      <Grid item>Rating</Grid>
                    </Grid> */}
                    {/* <Grid item xs={2} container justifyContent="center">
                      <Grid item>Voice</Grid>
                    </Grid> */}
                  </Grid>
                </Grid>
                {/* <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  gap={4}
                  marginTop={1}
                >
                  <Grid
                    item
                    container
                    xs={3}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Grid
                      item
                      sx={{
                        color: "#5570DC",
                      }}
                      marginTop={0.6}
                    >
                      <TuneIcon fontSize="small" />
                    </Grid>
                    <Grid item>Filter</Grid>
                  </Grid>

                  <Grid item xs={8.5} container gap={5}>
                    <label>
                      <input
                        type="radio"
                        checked={filterType === "displayAll"}
                        onChange={() => {
                          filterByDisplayAll();
                          setFilterType("displayAll");
                        }}
                      />
                      Display All
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={filterType === "emptyTranslatedAudio"}
                        onChange={() => {
                          filterByEmptyTranslationsAudios();
                          setFilterType("emptyTranslatedAudio");
                        }}
                      />
                      Empty Translated Audio
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={filterType === "emptyTranslatedText"}
                        onChange={() => {
                          filterByEmptyTranslationsText();
                          setFilterType("emptyTranslatedText");
                        }}
                      />
                      Empty Translated Text
                    </label>

                    <label>
                      <input
                        type="radio"
                        checked={filterType === "displayNotes"}
                        onChange={() => {
                          filterByNotes();
                          setFilterType("displayNotes");
                        }}
                      />
                      Display Notes
                    </label>
                  </Grid>
                </Grid> */}
                <Grid
                  item
                  container
                  direction="row"
                  marginBottom={4}
                  marginTop={2}
                  className={classes["AudioWorkPlaceChatBoxScroller"]}
                  ref={containerRef}
                >
                  {audioData ? (
                    <>
                      {audioData.map((data, index) => (
                        <React.Fragment key={index}>
                          <Grid
                            item
                            container
                            direction="row"
                            // justifyContent="space-between"
                            marginBottom={0.5}
                          >
                            <Grid
                              xs={1.0}
                              item
                              container
                              direction="row"
                              sx={{
                                backgroundColor: `${secondaryColor}`,

                                height: "120px",
                                borderTopLeftRadius: "10px",
                                borderBottomLeftRadius: "10px",
                              }}
                              marginLeft={2}
                              // paddingTop={0}
                              alignContent="center"
                              justifyContent="center"
                              gap={1.5}
                            >
                              <Grid
                                item
                                sx={{
                                  color: `${primaryColor}`,
                                  fontSize: "18px",
                                  // fontWeight: "600",
                                  maxWidth: "100px",
                                  minWidth: "55px",
                                }}
                              >
                                {data.speaker ? (
                                  findDisplayName(data.speaker)
                                ) : (
                                  <Grid fontSize="14px">NO SPEAKER</Grid>
                                )}
                              </Grid>

                              <Grid item>
                                <textarea
                                  disabled={true}
                                  style={{
                                    // color: `${secondaryColor}`,
                                    color: "black",
                                  }}
                                  className={
                                    classes[
                                    "AudioWorkPlaceTimelineEditTranslation"
                                    ]
                                  }
                                  value={formatTime(data.start)}
                                  type="text"
                                  onChange={(e) =>
                                    handleStartTimeChange(e, data.id)
                                  }
                                ></textarea>
                              </Grid>
                              <Grid item>
                                <textarea
                                  disabled={true}
                                  style={{
                                    // color: `${secondaryColor}`,
                                    color: "black",
                                  }}
                                  className={
                                    classes[
                                    "AudioWorkPlaceTimelineEditTranslation"
                                    ]
                                  }
                                  value={formatTime(data.end)}
                                  type="text"
                                  onChange={(e) =>
                                    handleEndTimeChange(e, data.id)
                                  }
                                ></textarea>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              container
                              className={classes["AudioWorkPlaceBoxes"]}
                              justifyContent="space-between"
                              direction="row"
                              paddingLeft={2}
                              paddingRight={2}
                              paddingTop={1}
                              // gap={7}
                              xs={10.76}
                              onClick={() => {
                                setSeekTime(data.start);
                                setSeekTimeIndex(prev => prev === 1 ? 2 : 1);
                                scrollToItem(data.id);
                              }}
                              sx={{ cursor: "pointer" }}
                              height="120px"
                            >
                              <Grid
                                xs={3}
                                item
                                container
                                direction="column"
                                // marginTop={1}
                                gap={1.5}
                              >
                                <Grid item>
                                  <ReactAudioPlayer
                                    style={{
                                      width: "80%",
                                      height: "14px",
                                    }}
                                    src={data.originalAudio}
                                    // onLoadedMetadata={}

                                    controls
                                  />
                                </Grid>

                                <Grid item>
                                  <textarea
                                    className={classes["AudioWorkTextEditInput"]}
                                    style={{
                                      color:
                                        currentTime >= data.start && currentTime < data.end
                                          ? "red"
                                          : "",
                                    }}
                                    value={data.text}
                                    type="text"
                                    onChange={(e) => handleTextChange(e, data.id)}
                                  ></textarea>
                                </Grid>
                              </Grid>
                              <Grid
                                xs={3}
                                item
                                container
                                direction="row"
                              // marginTop={1}
                              >
                                {data.keepOriginalText ? (
                                  <></>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      container
                                      direction="column"
                                      // alignContent="center"

                                      gap={1.5}
                                    >
                                      <Grid item>
                                        <Grid
                                          item
                                          fontSize={13}
                                          color="#5570DC"
                                          container
                                          direction="row"
                                          gap={1}
                                        >
                                          <Grid item>
                                            <select
                                              className={
                                                classes[
                                                "AudioWorkSenetenceSelector"
                                                ]
                                              }
                                              // disabled={true}
                                              style={{ width: "200px" }}
                                              value={
                                                findTransObjectsbyLangCode(
                                                  data.translations
                                                ).translatedText
                                              }
                                              onChange={(e) => {
                                                handleTranslatedSentenceChange(
                                                  e,
                                                  data.id
                                                );
                                              }}
                                              disabled={true}
                                            >
                                              <option value="">
                                                Alternative sentences
                                              </option>

                                              {findTransObjectsbyLangCode(
                                                data?.translations
                                              )?.audioAttempts?.map(
                                                (sentence, index) => (
                                                  <option
                                                    key={index}
                                                    value={
                                                      sentence.translatedText
                                                    }
                                                  >
                                                    {sentence.translatedText}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <textarea
                                          className={
                                            classes["AudioWorkTextEditInput"]
                                          }
                                          style={{
                                            color:
                                              currentTime >= data.start &&
                                                currentTime < data.end
                                                ? "red"
                                                : "black",
                                          }}
                                          value={
                                            findTransObjectsbyLangCode(
                                              data.translations
                                            ).translatedText
                                          }
                                          type="text"
                                          onChange={(e) =>
                                            handleTranslationChange(e, data.id)
                                          }
                                        ></textarea>
                                      </Grid>
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                              <Grid xs={0.9} item container direction="column">
                                <Grid
                                  item
                                  container
                                  // alignItems="center"
                                  direction="column"
                                >
                                  <Grid
                                    item
                                    fontSize={14}
                                    fontWeight={500}
                                    marginTop={1.5}
                                    marginRight={1.5}
                                  >
                                    Use original text
                                  </Grid>

                                  <Grid item marginLeft={5}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={data.keepOriginalText}
                                        />
                                      }
                                      // label="Use origin"
                                      // labelPlacement="right"
                                      onClick={() =>
                                        handleKeepOriginalText(data.id)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid xs={2} item container direction="column">
                                <Grid item marginTop={2}>
                                  <textarea
                                    className={
                                      classes["AudioWorkNoteEditInput"]
                                    }
                                    value={
                                      findTransObjectsbyLangCode(
                                        data.translations
                                      ).translatedAudioNote
                                        ? findTransObjectsbyLangCode(
                                          data.translations
                                        ).translatedAudioNote
                                        : ""
                                    }
                                    placeholder="add note here"
                                    type="text"
                                    onChange={(e) =>
                                      handleNoteChange(e, data.id)
                                    }
                                  ></textarea>
                                </Grid>
                              </Grid>

                              {/* <Grid xs={2} item container direction="column">
                                <Grid
                                  item
                                  container
                                  marginBottom={0.5}
                                  alignItems="center"
                                  marginTop={2}
                                  direction="column"
                                >
                                  <Grid item>
                                    <Rating
                                      size="large"
                                      value={
                                        findTransObjectsbyLangCode(
                                          data.translations
                                        ).translatedAudioRating
                                          ? findTransObjectsbyLangCode(
                                              data.translations
                                            ).translatedAudioRating
                                          : 0
                                      }
                                      precision={1}
                                      onChange={(event, newValue) => {
                                        handleDialogueRating(newValue, data.id);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item>
                                    {findTransObjectsbyLangCode(
                                      data.translations
                                    ).translatedAudioRating ? (
                                      <Grid>
                                        Rate:
                                        {
                                          findTransObjectsbyLangCode(
                                            data.translations
                                          ).translatedAudioRating
                                        }
                                      </Grid>
                                    ) : (
                                      <Grid fontSize={14.5}>Not rated</Grid>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid> */}

                              {/* <Grid
                                xs={1}
                                item
                                container
                                direction="column"
                                alignItems="center"
                              >
                                {data.record ? (
                                  <Grid item marginTop={2}>
                                    <Grid item>
                                      <ReactRecorder
                                        setNewAudioRecorder={
                                          setNewAudioRecorder
                                        }
                                        dialogueData={data}
                                        handleRecordedAudio={
                                          handleRecordedAudio
                                        }
                                      />
                                    </Grid>

                                    {newAudioRecorder ? (
                                      <Grid
                                        container
                                        item
                                        justifyContent="center"
                                        marginTop={2}
                                      >
                                        <Grid item>
                                          <ReactAudioPlayer
                                            style={{
                                              width: "100px",
                                              height: "14px",
                                            }}
                                            src={newAudioRecorder.blobURL}
                                            controls
                                          />
                                        </Grid>
                                      </Grid>
                                    ) : (
                                      <></>
                                    )}
                                  </Grid>
                                ) : (
                                  <Grid marginBottom={2} marginTop={2}>
                                    <Grid
                                      item
                                      sx={{
                                        cursor: "pointer",
                                        color: "#5570DC",
                                      }}
                                    >
                                      <MicNoneIcon
                                        onClick={() =>
                                          startRecordingVoice(data.id)
                                        }
                                        fontSize="large"
                                      />
                                    </Grid>
                                  </Grid>
                                )}

                                {findTransObjectsbyLangCode(data.translations)
                                  .translatedRecording ? (
                                  <ReactAudioPlayer
                                    style={{
                                      width: "70%",
                                      height: "14px",
                                    }}
                                    src={
                                      findTransObjectsbyLangCode(
                                        data.translations
                                      ).translatedRecording
                                    }
                                    controls
                                  />
                                ) : (
                                  <>
                                    {data.record ? (
                                      <></>
                                    ) : (
                                      <Grid fontSize={10}>No rec voice</Grid>
                                    )}
                                  </>
                                )}
                              </Grid> */}
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              marginTop={3}
            >
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                gap={2}
              >
                <Grid item>
                  <TaskButton
                    onClick={() => nextPrevPageAPI("previous")}
                    width="80px"
                    disabled={Number(pageNumber) === 1 || pageButtonActivity}
                  >
                    Previous
                  </TaskButton>
                </Grid>

                <Grid item>
                  <span>
                    Page {pageNumber} of {totalPages}
                  </span>
                </Grid>

                <Grid item>
                  <TaskButton
                    width="80px"
                    onClick={() => nextPrevPageAPI("next")}
                    disabled={
                      Number(pageNumber) === totalPages || pageButtonActivity
                    }
                  >
                    Next
                  </TaskButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              gap={4}
              marginTop={1}
              marginLeft={13.8}
            >
              <Grid item>
                <input
                  type="text"
                  style={{ width: "40px" }}
                  value={goToPageNumber}
                  // placeholder="Enter the page number "
                  onChange={(e) => {
                    setGoToPageNumber(e.target.value >= 1 ? e.target.value : 1);
                  }}
                />
              </Grid>
              <Grid item>
                <TaskButton
                  onClick={() => goToPageAPI(goToPageNumber)}
                  width="80px"
                >
                  Go
                </TaskButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TranslatorScreen;
